import React from "react"

import BaseTable from "./BaseTable"

import InsuranceActionsCell from "./cells/InsuranceActionsCell"

import Backend from '../../../utils/Backend'

export default class InsuranceTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }
  }

  _deactivate(insurance){
    Backend.deactivateInsurance(insurance)
  }

  _activate(insurance){
    Backend.activateInsurance(insurance)
  }

  _getColumns() {
    return [
      {
        Header: 'Insurer',
        accessor: 'insurer_name'
      },
      {
        Header: 'Title',
        accessor: 'cta_title'
      },
      {
        Header: 'Subtitle',
        accessor: 'cta_subtitle'
      },
      // {
      //   Header: 'Impressions',
      //   accessor: 'impressions'
      // },
      // {
      //   Header: 'Clicks',
      //   accessor: 'clicks'
      // },
      {
        id: "active",
        Header: 'Actions',
        Cell: rowInfo => {
          return (
            <InsuranceActionsCell
              insurance={rowInfo.original}
              onDeactivateClick={insurance => this._deactivate(insurance)}
              onActivateClick={insurance => this._activate(insurance)}
              onEditClick={insurance => this.props.onEditInsuranceClick(insurance)}
            />
          )
        }
      }
    ]
  }

  render() {
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          endpoint={window.Api.Insurance}
          columns={columns}
        />
      </div>
    )
  }
}
