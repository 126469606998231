import React from "react"

import Div from './layouts/Div'
import MySelect from './common/MySelect'

import Backend from '../../utils/Backend'

export default class ScreenDetail extends React.Component {
  constructor(props){
    super(props)

    let addMode = props.screen == null

    this.state = {
      addMode,
      screen: props.screen || { name: "", description: "", type: "", status: "" }
    }
  }

  _isValid(data){
    let error = null;
    const {
      name,
      description,
      status
    } = data;

    if (name === "") error = "Enter name please!";
    if (description === "") error = "Enter description please!";
    if (status === "") error = "Select status please!";

    if(error) {
      alert(error);
      return false;
    }

    return true
  }

  _handleSave(){
    let {
      screen
    } = this.state

    if(!this._isValid(screen)){
      return
    }

    this._save()
  }

  _save(){
    let {
      screen,
      addMode
    } = this.state

    this.setState({loading: true})

    let modifiedScreen = screen.sections
      ? {
          ...screen,
          sections: screen.sections.map((sec) => ({
            ...sec,
            section: sec.section.id,
          })),
        }
      : { ...screen };

    this._updateBackend(addMode, modifiedScreen)
    .then(screen => {
      this.setState({
        screen,
        loading: false,
        addMode: false
      })
      this.props.goToScreens()
    })
    .catch(error => {
      alert(error.message)
      this.setState({loading: false})
    })
  }

  _updateBackend(addMode, screen){
    if(addMode) return Backend.createScreen(screen)
    else return Backend.editScreen(screen)
  }

  _getScreenStatuses() {
    return [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" }
    ]
  }

  render() {
    let {
      addMode,
      screen,
      loading
    } = this.state

    let title = addMode ? "Save" : "Update"


    return (
      <div className="row">
				<div className="col-md-6">
					<Div className="kt-portlet" disabled={loading}>
						<div className="kt-portlet__head">
							<div className="kt-portlet__head-label">
								<h3 className="kt-portlet__head-title">
                  Screen
								</h3>
							</div>
						</div>


						<form className="kt-form">
							<div className="kt-portlet__body">
								<h5 className="kt-section__title">Details:</h5><br/>

								<div className="form-group">
									<label>Name</label>
									<input type="text" className="form-control"
                    value={screen.name}
                    onChange={e => {
                      screen.name = e.target.value
                      this.setState({ screen })
                    }}
                    placeholder="Name"
                  />
								</div>

                <div className="form-group">
                  <label>Description</label>
                  <textarea value={screen.description}
                    onChange={e => {
                      screen.description = e.target.value
                      this.setState({ screen })
                    }}
                    placeholder="Description" className="form-control" />
                </div>

                <div className="form-group">
                  <label>Status</label>
                  <MySelect
                    value={screen.status}
                    options={this._getScreenStatuses()}
                    onChangeSelect={(e) => {
                      screen.status = e.target.value;
                      this.setState({
                        screen
                      });
                    }}
                  />
                </div>
							</div>

							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<button type="reset" onClick={() => this._handleSave()} className="btn btn-primary">
                    { title }
                  </button>
								</div>
							</div>
						</form>
					</Div>
				</div>
			</div>
    )
  }
}
