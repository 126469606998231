import React from "react"

import BaseTable from "./BaseTable"

import CustomerActionsCell from "./cells/CustomerActionsCell"

import Backend from '../../../utils/Backend'
export default class CustomersTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }
  }

  handleStatusChange = (updatedCustomer) => {
    // Here you can update the state or make an API call to save the status change
    console.log('Customer status updated:', updatedCustomer.user.status);
    if(updatedCustomer.user.status === "active"){
      Backend.activateCustomer(updatedCustomer)
    }
    else if(updatedCustomer.user.status === "banned"){
      Backend.deactivateCustomer(updatedCustomer)
    }
    else if(updatedCustomer.user.status === "limited-account"){
      Backend.limitCustomer(updatedCustomer)
    }
    else{
      console.log("Invalid status")
    }
    // For example, update the customer in the parent component's state or send the update to a server
  };

  _deactivate(dealer){
    Backend.deactivateCustomer(dealer)
    this.props.onDeactivatedCustomer(dealer)
  }

  _activate(dealer){
    Backend.activateCustomer(dealer)
    this.props.onActivatedCustomer(dealer)
  }

  _getColumns() {
    return [
      {
        Header: 'Name',
        id: 'user.first_name',
        accessor: customer => `${customer.user.first_name} ${customer.user.last_name}`
      },
      {
        Header: 'Phone',
        id: 'user.phone_country_code',
        accessor: customer => customer.user.phone_country_code ? `${customer.user.phone_country_code} ${customer.user.phone_number}` : "-"
      },
      {
        Header: 'Email',
        accessor: 'user.email',
      },
      {
        Header: 'Active Listings #',
        accessor: 'active_vehicles_no'
      },
      {
        Header: 'Total Listings #',
        accessor: 'total_vehicles_no'
      },

      {
        Header: 'Liked Listings #',
        accessor: 'total_liked_vehicles_no'
      },

      {
        Header: 'Disliked Listings #',
        accessor: 'total_disliked_vehicles_no'
      },
      {
        Header: 'Lists #',
        accessor: 'collections_no'
      },
      {
        Header: 'Location',
        accessor: 'location.state'
      },
      {
        id: "active",
        Header: 'Actions',
        Cell: rowInfo => {
          return (
            <CustomerActionsCell
              customer={rowInfo.original}
              onStatusChange={this.handleStatusChange}
            />
          )
        }
      }
    ]
  }

  render() {
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          endpoint={window.Api.Customers}
          columns={columns}
        />
      </div>
    )
  }
}
