import React from 'react'

export default class VehicleActionsCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      vehicle: props.vehicle
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderRefund(vehicle){
    let {
      payment
    } = vehicle

    if(payment == null || payment.status === "refunded"){
      return null
    }

    return (
      <a href="#"
        className={`btn btn-sm btn-label-danger btn-bold`}
        style={{ marginLeft: 10 }}
        onClick={e => {
          e.preventDefault()

          this.props.onRefundClick(vehicle)
          vehicle.payment.status = "refunded"

          this.setState({ vehicle })
        }}
      >
        Refund
      </a>
    )
  }
  _renderToggleActivate(vehicle){

    let label = vehicle.active ? "warning" : "info"
    let title = vehicle.active ? "Unpublish" : "Publish"

    return (
      <a href="#"
        className={`btn btn-sm btn-label-${label} btn-bold`}
        onClick={e => {
          e.preventDefault()

          if(vehicle.active){
            this.props.onDeactivateClick({ ...vehicle })
          }
          else{
            this.props.onActivateClick({ ...vehicle })
          }

          vehicle.active = !vehicle.active

          this.setState({ vehicle })
        }}
      >
        { title }
      </a>
    )
  }


  render() {
    let { vehicle } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative', width: '110px'}}>
        { this._renderToggleActivate(vehicle) }
        {/* }{ this._renderRefund(vehicle) } */}
      </span>
    )
  }
}
