import React from 'react'

import BackgroundImage from '../assets/media/bg/bg-3.jpg'

import AuthManager from '../utils/AuthManager'
import Email from '../utils/Email'

export default class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: ''
    }
  }

  _isFormValid() {
    let { email } = this.state
    
    if (!Email.isValid(email)) {
      this.setState({ error: 'Please enter a valid email address' })
      return false
    }

    return true
  }

  _handleLogInPressed() {
    if (!this._isFormValid()) {
      return
    }
    let { email, password } = this.state

    AuthManager.login(email, password)
      .then(() => {
        this.props.history.push('/'); 
      })
      .catch(error => {
        this.setState({ isLoading: false, error: error.message })
      })
  }

  _handleForgotPasswordPressed() {
    this.props.history.push('forgot-password')
  }

  _handleKeyDown(e){
    if (e.key === 'Enter') {
      e.preventDefault()
      this._handleLogInPressed()
    }
  }

  _renderError() {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div
        class="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    )
  }

  render() {
    return (
      <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
          style={{ backgroundImage: 'url(' + BackgroundImage + ')' }}
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div className="kt-login__container">
              <div className="kt-login__logo">
                <a href="/#">
                  <img
                    alt ='logo'
                    src={require('../assets/img/logo.png')}
                    height="80px"
                  />
                </a>
              </div>
              <div className="kt-login__signin">
                <div className="kt-login__head">
                  <h3 className="kt-login__title">
                    Log in to your Dashboard
                  </h3>
                </div>
                <div className="kt-form">
                  {this._renderError()}
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={event =>
                        this.setState({ email: event.target.value, error: null })
                      }
                      onKeyDown={e => this._handleKeyDown(e)}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={event =>
                        this.setState({ password: event.target.value, error: null })
                      }
                      onKeyDown={e => this._handleKeyDown(e)}
                    />
                  </div>
                  <div className="row kt-login__extra">
                    <div className="col kt-align-right">
                      <a href="#" onClick={() => this._handleForgotPasswordPressed()}>
                        Forgot Password?
                      </a>
                    </div>
                  </div>
                  <div className="kt-login__actions">
                    <button
                      onClick={() => this._handleLogInPressed()}
                      className="btn btn-brand btn-pill btn-elevate kt-login__btn-primary"
                    >
                      Log In
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
