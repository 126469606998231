import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from '../../../utils/Backend'

export default class ImportPhoneNumberModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      key: null,
      name: null,
      loading: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _submit(){
    let {
      name,
      key
    } = this.state

    if(!name){
      alert("Please enter a name")
      return
    }

    if(!key){
      alert("Please enter an ID")
      return
    }

    this.setState({ loading: true })

    Backend.addSkupeNetDealer(name, key)
    .then(skupeNetDealer => {
      this.setState({ loading: false })
      this.props.onSubmit()
    })
    .catch(error => {
      this.setState({ loading: false })
      alert(error.message)
    })
  }

  render() {
    let {
      show,
      data,
      loading
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>SkupeNet Dealer</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group mt-3">
            <label>Name</label>
            <input
              type="text"
              class="form-control"
              onChange={e => {
                let name = e.target.value
                this.setState({ name })
              }}
            />
          </div>

          <div className="form-group mt-3">
            <label>ID</label>
            <input
              type="text"
              class="form-control"
              onChange={e => {
                let key = e.target.value
                this.setState({ key })
              }}
            />
          </div>

        </Modal.Body>

        { !loading &&
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}>Cancel</button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this._submit()}>Submit</button>
        </Modal.Footer>
        }
      </Modal>
    )
  }
}
