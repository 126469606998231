import React from "react"
import { withRouter } from "react-router-dom";

class SubHeader extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  componentDidMount(){
    this._updateActive()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this._updateActive();
    }
  }

  _updateActive() {
    switch(this.props.location.pathname){
      case "/":
        this._setActive("Dashboard")
        break;

      case "/dealers":
        this._setActive("Dealers")
        break;

      case "/dealer-listings":
        this._setActive("Dealer Listings")
        break;

      case "/private-listings":
        this._setActive("User Listings")
        break;

      case "/users":
        this._setActive("Users")
        break;

      case "/adverts":
        this._setActive("Adverts")
        break;

      case "/skupenet-dealers":
        this._setActive("Skupenet Dealers")
        break;

      default:
        // do nothing
        break;
    }
  }

  _setActive(title){
    this.setState({ title })
  }

  render() {
    let { title } = this.state

    return (
      <div className="kt-subheader   kt-grid__item" id="kt_subheader">
        <div className="kt-subheader__main">
          <h3 className="kt-subheader__title">{ title }</h3>
        </div>
        <div className="kt-subheader__toolbar">

        </div>
      </div>
    )
  }
}

export default withRouter(SubHeader);
