import React from "react"

import Div from '../layouts/Div'
import Backend from '../../../utils/Backend'
import PlaceholderImage from '../../../assets/img/placeholder-image.png'
import imageCompression from 'browser-image-compression';

export default class ImageEdit extends React.Component {
  constructor(props){
    super(props)

    this.imageHolderRef = React.createRef();

    this.state = {
      height: 0,
      image: this.props.image,
      loading: false
    }
  }


  onResize() {
    if (this.imageHolderRef.current) {
      this.setState({
        height: this.imageHolderRef.current.clientWidth / 16 * 10 // cacl image height so it has 16:10 ration
      });
    }
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize.bind(this));
  }

  componentWillReceiveProps(nextProps){
    this.setState({...nextProps})
  }

  _onImageChange = async (e) => {    
    const fileReader = new FileReader();
    const imageObject = new Image();
    fileReader.onload = () => {
      imageObject.src = fileReader.result;
    };
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    const image = e.target.files[0];
    if (image) fileReader.readAsDataURL(image);

    try {
      const compressedFile = await imageCompression(image, options);
  
      await Backend.uploadImage(compressedFile)
      .then(image => {
        this.setState({ loading: false })
        this.props.onUpdated(image)
      })
      .catch(error => {
        this.setState({ loading: false })
        alert(error.message)
      }) 
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    let {
      image
    } = this.state

    let url = image ? image.original : PlaceholderImage

    return (
			<Div disabled={this.state.loading}>
        <div className="" style={this.props.containerStyle}>
          <div className="kt-avatar kt-avatar--outline" style={{ margin: '0 auto', width: "100%" }}>
            <div
              ref={this.imageHolderRef}
              className="kt-avatar__holder"
              style={{
                backgroundImage: `url("${url}")`,
                backgroundSize: this.props.resizeMode,
                backgroundPosition: 'center',
                height: this.state.height + "px"
              }}>
            </div>
            <label className="kt-avatar__upload" data-toggle="kt-tooltip" title="" data-original-title="Change logo">
              <i className="fa fa-pen"></i>
              <input type="file" accept=".png, .jpg, .jpeg" onChange={e => this._onImageChange(e)} />
            </label>
            <span className="kt-avatar__cancel" data-toggle="kt-tooltip" title="" data-original-title="Cancel avatar">
              <i className="fa fa-times"></i>
            </span>
          </div>
        </div>
			</Div>
    )
  }
}

ImageEdit.defaultProps = {
  containerStyle: [],
  resizeMode: 'cover'
}
