import React from "react"

import Div from './components/layouts/Div'

import moment from 'moment'

import StatCard from './components/cards/StatCard'
import Card from './components/cards/Card'
import CardItem from './components/cards/CardItem'

import Backend from '../utils/Backend'


export default class Overview extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      searchTerm: '',
      loading: true,
      loadingLatestDealers: true,
      loadingLatestVehicles: true,
      stats: {},
      latestDealers: [],
      latestVehicles: [],
    }
  }

  componentDidMount(){
    this._loadStats()
    this._loadLatestDealers()
    this._loadLatestVehicles()
  }

  _onDealerClicked(dealer){
    this.props.history.push(`/dealers/${dealer.id}`)
  }

  _onVehicleClicked(vehicle){
    this.props.history.push(`/vehicles/${vehicle.id}`)
  }

  _renderLatestDealers(){
    let {
      latestDealers,
      loadingLatestDealers
    } = this.state

    if(loadingLatestDealers){
      return null
    }

    return latestDealers.map(dealer => {
      return (
        <CardItem
          key={dealer.id}
          heading={dealer.name}
          subHeading={moment(dealer.created_at).fromNow()}
          onClick={() => this._onDealerClicked(dealer)}
        />
      )
    })
  }

  _renderLatestVehicles(){
    let {
      latestVehicles,
      loadingLatestVehicles
    } = this.state

    if(loadingLatestVehicles){
      return null
    }

    return latestVehicles.map(vehicle => {
      return (
        <CardItem
          key={vehicle.id}
          heading={vehicle.model.manufacturer.name + " " + vehicle.model.name}
          subHeading={moment(vehicle.created_at).fromNow()}
          onClick={() => {}}
        />
      )
    })
  }

  _loadStats(){
    Backend.getAllStats()
    .then(stats => {
      if(stats){
        console.log(stats)
        this.setState({
          stats,
          loading: false
        })
      }
    
    })
    .catch(error => {
      alert(error.message)
    })
  }

  _loadLatestDealers(){
    Backend.getLatestDealers()
    .then(latestDealers => {
      this.setState({
        latestDealers,
        loadingLatestDealers: false
      })
    })
  }

  _loadLatestVehicles(){
    Backend.getLatestVehicles()
    .then(latestVehicles => {
      this.setState({
        latestVehicles,
        loadingLatestVehicles: false
      })
    })
  }

  render() {
    let {
      stats,
      loading
    } = this.state


    if(loading){
      return null
    }

    return (
      <Div
      >
        <div className="row">
  			  <StatCard
            title="Active Vehicles"
            value={ stats.vehicles ? stats.vehicles.active_no : 0 }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          
          <StatCard
            title="Users"
            value={ stats.customers ? stats.customers.total_no : 0 }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          <StatCard
            title="Dealers"
            value={ stats.dealers ? stats.dealers.total_no : 0 }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          <StatCard
            title="Active Dealers"
            value={ stats.dealers ? stats.dealers.active_no : 0}
            size="col-md-3 col-lg-3 col-xl-3"
          /> 
  			</div> 
        <div className="row">
          <Card
            title="Latest Dealers"
          >
            { this._renderLatestDealers() }
          </Card>
          <Card
            title="Latest Vehicles"
          >
            { this._renderLatestVehicles() }
          </Card>
        </div>
      </Div>
    )
  }
}
