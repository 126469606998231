import React from "react"

import Card from './components/cards/Card'
import CollectionsTable from './components/tables/CollectionsTable'
import Backend from '../utils/Backend'

import AddCollectionToSetModal from './components/modal/AddCollectionToSetModal'

export default class CollectionsInSet extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      collections: [],
      collection_set: null,
      showAddModal: false,
      searchTerm: '',
      statusFilters: [],
    }
  }

  componentDidMount(){
    this._loadCollections();
    this._loadCollectionSet();
  }

  _loadCollections() {
    Backend.getCollectionsNames().then((data) => {
      this.setState({collections: data})
    })
  }

  _loadCollectionSet(){
    let id = this.props.match.params.collection_set_id
    if(!id) this.props.history.push("/collection/collection_sets");
    Backend.getCollectionSet(id).then((data) => {
      if (data) {
        this.setState({
          collection_set: data,
          loading: false
        })
      }
    });
  }

  replaceCollectionWithCollectionId(collections) {
    for (let collection = 0; collection < collections.length; ++collection) {
      collections[collection].collection = collections[collection].collection.id;
    }
  }

  _onUpdateOrderInSet = (data) => {
    const copy_set = Object.assign({}, this.state.collection_set);
    const collections_copy = [];
    data.forEach((collection) => {
      collections_copy.push({
        collection: collection.id, order: collection.order
      });
    });
    copy_set.collections = collections_copy;
    Backend.editCollectionSet(copy_set).catch(error => alert(error.message));
  }

  render() {
    let {
      loading,
      showAddModal,
      collections,
      collection_set,
    } = this.state

    if (loading) {
      return null
    }

    return (
      <div>
        <div className="row">
          <Card
            title={collection_set === null ? "Collections" : "Collection Set: " + collection_set.name}
            className="col-xl-12"
            renderToolbar={() => {
              return (
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    &nbsp;
                    <div className="dropdown dropdown-inline">
                      <button
                        type="button"
                        className="btn btn-brand btn-icon-sm"
                        onClick={() => {this.setState({showAddModal: true})}}
                      >
                        <i className="flaticon2-plus"></i> Add Collection
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          >
            <CollectionsTable
              collection_set={collection_set}
              onUpdateOrderInSet={this._onUpdateOrderInSet}
              onRemoveCollectionClick={collection => {
                this.replaceCollectionWithCollectionId(collection_set.collections);
                for(let index = 0; index < collection_set.collections.length; ++index) {
                  if (collection_set.collections[index].collection === collection.id) {
                    collection_set.collections.splice(index, 1);
                    break;
                  }
                }
                Backend.editCollectionSet(collection_set)
                .then(() => {
                  this.props.history.push(`/collection/collections/${collection_set.id}`)
                  document.location.reload();
                })
                .catch((error) => {
                  alert(error.message);
                  document.location.reload();
                });
              }}
            />
          </Card>
          <AddCollectionToSetModal
            show={showAddModal}
            collections={collections}
            collection_set={collection_set}
            onSubmit={() => {
              this.setState({ showAddModal: false })
              document.location.reload();
            }}
            onHide={() => this.setState({ showAddModal: false })}
          />
        </div>
      </div>
    )
  }
}
