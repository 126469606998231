import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from '../../utils/Backend'
import ParentItemModal from "./ParentItemModal";

export default class NewChildItemModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      show: props.show,
      childItem: {},
      selectedParent: "",
      showParentItemModal: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  onFieldChange = (field, value) => {
    const updated = { ...this.state.childItem };
    updated[field] = value;
    this.setState({ childItem: updated });
  }

  _submit = () => {
    Backend.createChildMappedItem(this.state.childItem)
      .then(res => {
        this.props.onHide(true)
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  _renderError = () => {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div className="kt-alert kt-alert--outline alert alert-danger alert-dismissible" role="alert">
        <span>{error}</span>
      </div>
    )
  }

  render() {
    let { show } = this.state;

    const isEnabled = this.state.childItem && this.state.childItem.parent && this.state.childItem.value;

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Child Item</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this._renderError()}

          <div className="form-group">
            <label>Select Parent</label>
            <input type="text" className="form-control"
              value={this.state.selectedParent}
              readOnly
              placeholder="Parent"
              onClick={() => this.setState({ showParentItemModal: true })}
            />
          </div>

          <div className="form-group">
            <label>Value</label>
            <input type="text" className="form-control"
              value={this.state.childItem.value || ""}
              onChange={e => this.onFieldChange('value', e.target.value)}
              placeholder="Value"
            />
          </div>

          {this.state.showParentItemModal ? <ParentItemModal show={true} onSelect={(item, record) => {
            this.setState({selectedParent: record['value']});
            this.onFieldChange('parent', item);
            this.setState({ showParentItemModal: false });
          }} onHide={() => {
            this.setState({ showParentItemModal: false });
          }} /> : null}
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}>Cancel</button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={!isEnabled}
            onClick={() => this._submit()}>Submit</button>
        </Modal.Footer>
        
      </Modal>
    )
  }
}
