import React from "react"

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'

import Card from './cards/Card'
import Div from './layouts/Div'

import ImageEdit from './common/ImageEdit'
import ColorPicker from './common/ColorPicker'

import Backend from '../../utils/Backend'

export default class AdvertDetail extends React.Component {
  constructor(props){
    super(props)

    let addMode = props.advert == null

    this.state = {
      addMode,
      advert: props.advert || { }
    }
  }

  _isValid(data){
    let error = null;


    if (!data.placement){
      error = "You must specify a placement"
    }
    else if (!data.cta_link){
      error = "You must include a cta link"
    }
    else if (!data.starts_at){
      error = "You must include a start date"
    }
    else if (!data.ends_at){
      error = "You must include a end date"
    }

    if(error){
      alert(error);
      return false;
    }

    return true
  }

  _handleSave(){
    let {
      advert
    } = this.state

    if(!this._isValid(advert)){
      return
    }

    this._save()
  }

  _save(){
    let {
      advert,
      addMode
    } = this.state

    this.setState({loading: true})

    this._updateBackend(addMode, advert)
    .then(advert => {
      this.setState({
        advert,
        loading: false,
        addMode: false
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({loading: false})
    })
  }

  _updateBackend(addMode, advert){
    if(addMode){
      return Backend.createAdvert(advert)
    }
    else{
      return Backend.editAdvert(advert)
    }
  }

  _renderPreviewCardStack(advert, cover, logo){
    if(advert.placement !== "all" && advert.placement !== "card_stack"){
      return
    }

    return (
      <div class="advert_preview_card_stack advert_preview_background"
        style={{
          backgroundColor: advert.background_color,
          background: `url("${cover}") no-repeat`,
          backgroundSize: 'cover'
        }}
      >
        <h3 className="advert_preview_title"
          style={{
            color: advert.title_color,
          }}
        >
          {advert.title}
        </h3>
        <button
          href={advert.cta_link}
          className="advert_preview_cta"
          style={{
            color: advert.cta_text_color,
            background: advert.cta_background_color
          }}
        >
          { advert.cta_text }
        </button>
        <img className="advert_preview_logo" src={logo}/>
      </div>
    )
  }

  _renderPreviewBanner(advert, cover, logo){
    if(advert.placement !== "all" && advert.placement !== "banner"){
      return
    }

    return (
      <div class="advert_preview_banner advert_preview_background"
        style={{
          backgroundColor: advert.background_color,
          background: `url("${cover}") no-repeat`,
          backgroundSize: 'cover'
        }}
      >
        <h3 className="advert_preview_title"
          style={{
            color: advert.title_color,
          }}
        >
          {advert.title}
        </h3>
        <button
          href={advert.cta_link}
          className="advert_preview_cta"
          style={{
            color: advert.cta_text_color,
            background: advert.cta_background_color
          }}
        >
          { advert.cta_text }
        </button>
        <img className="advert_preview_logo" src={logo}/>
      </div>
    )
  }

  render() {
    let {
      addMode,
      advert,
      loading
    } = this.state

    console.log("bbbbbb")

    let title = addMode ? "Save" : "Update"

    let cover = advert.background_image ? advert.background_image.cover : null
    let logo = advert.logo ? advert.logo.cover : null

    return (
      <div className="row">
        <div className="col-md-6">
          <Div className="kt-portlet" disabled={loading}>
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Advert</h3>
              </div>
            </div>

            <form className="kt-form">
              <div className="kt-portlet__body">
                <h5 className="kt-section__title">Details:</h5>
                <br />

                <div className="form-group">
                  <label>Type</label>
                  <select
                    className="form-control m-t-0 b-space"
                    value={advert.type}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value === "") {
                        value = null;
                      }
                      advert.type = value;
                      this.setState({ advert });
                    }}
                  >
                    <option value="" disabled></option>
                    <option value="advert">Advert</option>
                    <option value="refer_sweep">Refer Sweep</option>
                  </select>
                </div>

                <label>Background Image</label>
                <ImageEdit
                  image={advert.background_image}
                  onUpdated={(image) => {
                    advert.background_image = image;
                    this.setState({ advert });
                  }}
                />

                <label>Logo Image</label>
                <ImageEdit
                  image={advert.logo}
                  onUpdated={(image) => {
                    advert.logo = image;
                    this.setState({ advert });
                  }}
                />

                {this.state.advert.type === "refer_sweep" ? (
                  <>
                    <label>Large Advert Image</label>
                    <ImageEdit
                      image={advert.large_advert_image}
                      onUpdated={(image) => {
                        advert.large_advert_image = image;
                        this.setState({ advert });
                      }}
                    />

                    <label>Small Advert Image</label>
                    <ImageEdit
                      image={advert.small_advert_image}
                      onUpdated={(image) => {
                        advert.small_advert_image = image;
                        this.setState({ advert });
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}

                <div className="form-group">
                  <label>Placement (where should the ad appear)</label>
                  <select
                    className="form-control m-t-0 b-space"
                    value={advert.placement}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value === "") {
                        value = null;
                      }
                      advert.placement = value;
                      this.setState({ advert });
                    }}
                  >
                    <option value="">Placement</option>
                    <option value="all">Everywhere</option>
                    <option value="card_stack">Card Stack</option>
                    <option value="banner">Banner</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={advert.title}
                    onChange={(e) => {
                      advert.title = e.target.value;
                      this.setState({ advert });
                    }}
                    placeholder="Name"
                  />
                </div>

                <ColorPicker
                  title="Title Color"
                  value={advert.title_color}
                  onChange={(color) => {
                    console.log("ccc", color);
                    advert.title_color = color;
                    this.setState({ advert });
                  }}
                />

                <div className="form-group">
                  <label>CTA Text</label>
                  <input
                    type="text"
                    className="form-control"
                    value={advert.cta_text}
                    onChange={(e) => {
                      advert.cta_text = e.target.value;
                      this.setState({ advert });
                    }}
                    placeholder="CTA"
                  />
                </div>

                <ColorPicker
                  title="CTA Text Color"
                  value={advert.cta_text_color}
                  onChange={(color) => {
                    advert.cta_text_color = color;
                    this.setState({ advert });
                  }}
                />

                <ColorPicker
                  title="CTA Background Color"
                  value={advert.cta_background_color}
                  onChange={(color) => {
                    advert.cta_background_color = color;
                    this.setState({ advert });
                  }}
                />

                <div className="form-group">
                  <label>CTA Link</label>
                  <input
                    type="text"
                    className="form-control"
                    value={advert.cta_link}
                    onChange={(e) => {
                      advert.cta_link = e.target.value;
                      this.setState({ advert });
                    }}
                    placeholder="CTA"
                  />
                </div>

                <ColorPicker
                  title="Background Color"
                  value={advert.background_color}
                  onChange={(color) => {
                    advert.background_color = color;
                    this.setState({ advert });
                  }}
                />

                <div className="form-group">
                  <label htmlFor="testDates" id="testDates">Start Date</label>
                  <div className="input-group pull-right">
                    <Flatpickr
                      className="form-control"
                      value={advert.starts_at}
                      options={{
                        enableTime: true,
                        noCalendar: false,
                        dateFormat: "Y-m-d H:i",
                      }}
                      onChange={(dates) => {
                        advert.starts_at = dates[0];
                        this.setState({ advert });
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="la la-calendar-check-o"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label id="testDates">End Date</label>
                  <div className="input-group pull-right">
                    <Flatpickr
                      className="form-control"
                      value={advert.ends_at}
                      options={{
                        enableTime: true,
                        noCalendar: false,
                        dateFormat: "Y-m-d H:i",
                      }}
                      onChange={(dates) => {
                        advert.ends_at = dates[0];
                        this.setState({ advert });
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="la la-calendar-check-o"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="kt-portlet__foot">
                <div className="kt-form__actions">
                  <button
                    type="reset"
                    onClick={() => this._handleSave()}
                    className="btn btn-primary"
                  >
                    {title}
                  </button>
                </div>
              </div>
            </form>
          </Div>
        </div>
        <div className="col-md-6">
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Previews</h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              {this._renderPreviewCardStack(advert, cover, logo)}
              {this._renderPreviewBanner(advert, cover, logo)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
