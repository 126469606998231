import React from "react"

import Div from './layouts/Div'
import MySelect from './common/MySelect'

import Backend from '../../utils/Backend'

export default class MarketingMessageDetail extends React.Component {
    constructor(props) {
        super(props)

        let addMode = props.marketingMessage == null
        this.state = {
            addMode,
            marketingMessage: props.marketingMessage ||
            {
                type: "push",
                frequency: "",
                segment: "",
                active: "",
                message_content: "",
                message_title: "",
                send_day: "",
                send_time: "",
                send_date: "",

            }
        }
    }

    _isValid(data) {
        let error = null;
        const {
            message_title,
            frequency,
            segment,
            message_content,
            send_time,
            active
        } = data;

        if (message_title === "") error = "Enter message title please!";
        if (frequency === "") error = "Select frequency please!";
        if (segment === "") error = "Select segment please!";
        if (message_content === "") error = "Select message content please!";
        if (send_time === "") error = "Select send time please!";
        if (active === "") error = "Select status please!";

        if (error) {
            alert(error);
            return false;
        }

        return true
    }

    _handleSave() {
        let {
            marketingMessage
        } = this.state

        if (!this._isValid(marketingMessage)) {
            return
        }

        this._save()
    }

    _save() {
        let {
            marketingMessage,
            addMode
        } = this.state

        this.setState({ loading: true })

        if(marketingMessage.frequency !== "one_off"){
            delete marketingMessage.send_date
        }
        
        this._updateBackend(addMode, marketingMessage)
            .then(marketingMessage => {
                this.setState({
                    marketingMessage,
                    loading: false,
                    addMode: false
                })
                this.props.goToMarketingMessages()
            })
            .catch(error => {
                alert(error.message)
                this.setState({ loading: false })
            })
    }

    _updateBackend(addMode, marketingMessage) {
        if (addMode) return Backend.createMarketingMessage(marketingMessage)
        else return Backend.editMarketingMessage(marketingMessage)
    }

    _getMarketingMessageStatuses() {
        return [
            { value: 1, label: "Active" },
            { value: 0, label: "Inactive" }
        ]
    }

    _getFrequency() {
        return [
            { value: 'one_off', label: "One off" },
            { value: 'weekly', label: "Weekly" },
            { value: 'biweekly', label: "Biweekly" }
        ]
    }

    _getDay() {
        return [
            { value: 'sunday', label: "Sunday" },
            { value: 'monday', label: "Monday" },
            { value: 'tuesday', label: "Tuesday" },
            { value: 'wednesday', label: "Wednesday" },
            { value: 'thursday', label: "Thursday" },
            { value: 'friday', label: "Friday" },
            { value: 'saturday', label: "Saturday" },

        ]
    }

    render() {
        let {
            addMode,
            marketingMessage,
            loading
        } = this.state

        let title = addMode ? "Save" : "Update"


        return (
            <div className="row">
                <div className="col-md-6">
                    <Div className="kt-portlet" disabled={loading}>
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Marketing Message
                                </h3>
                            </div>
                        </div>


                        <form className="kt-form">
                            <div className="kt-portlet__body">
                                <h5 className="kt-section__title">Details:</h5><br />

                                <div className="form-group">
                                    <label>Type</label>
                                    <input type="text" className="form-control"
                                        value={'push'}
                                        onChange={e => {
                                            marketingMessage.type = 'push'
                                            this.setState({ marketingMessage })
                                        }}
                                        placeholder="Type"
                                        disabled
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Frequency</label>
                                    <MySelect
                                        value={marketingMessage.frequency}
                                        options={this._getFrequency()}
                                        onChangeSelect={(e) => {
                                            marketingMessage.frequency = e.target.value;
                                            this.setState({
                                                marketingMessage
                                            });
                                        }}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Segment</label>
                                    <input value={marketingMessage.segment}
                                        onChange={e => {
                                            marketingMessage.segment = e.target.value
                                            this.setState({ marketingMessage })
                                        }}
                                        placeholder="Description" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>Message Content</label>
                                    <input value={marketingMessage.message_content}
                                        onChange={e => {
                                            marketingMessage.message_content = e.target.value
                                            this.setState({ marketingMessage })
                                        }}
                                        placeholder="Description" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>Message Title</label>
                                    <input value={marketingMessage.message_title}
                                        onChange={e => {
                                            marketingMessage.message_title = e.target.value
                                            this.setState({ marketingMessage })
                                        }}
                                        placeholder="Description" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>Send Day</label>
                                    <MySelect
                                        value={marketingMessage.send_day}
                                        options={this._getDay()}
                                        onChangeSelect={(e) => {
                                            marketingMessage.send_day = e.target.value;
                                            this.setState({
                                                marketingMessage
                                            });
                                        }}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Send Time</label>
                                    <input type="time" value={marketingMessage.send_time}
                                        onChange={e => {
                                            marketingMessage.send_time = e.target.value
                                            this.setState({ marketingMessage })
                                        }}
                                        placeholder="Description" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>Send Date</label>
                                    <input type="date" value={marketingMessage.send_date}
                                        onChange={e => {
                                            marketingMessage.send_date = e.target.value
                                            this.setState({ marketingMessage })
                                        }}
                                        disabled={marketingMessage.frequency === 'one_off' ? false : true}
                                        placeholder="Description" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>Status</label>
                                    <MySelect
                                        value={marketingMessage.active}
                                        options={this._getMarketingMessageStatuses()}
                                        onChangeSelect={(e) => {
                                            marketingMessage.active = e.target.value;
                                            this.setState({
                                                marketingMessage
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="kt-portlet__foot">
                                <div className="kt-form__actions">
                                    <button type="reset" onClick={() => this._handleSave()} className="btn btn-primary">
                                        {title}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Div>
                </div>
            </div>
        )
    }
}
