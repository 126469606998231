import React from 'react'

import Card from '../components/cards/Card'
import BaseTable from '../components/tables/BaseTable'
import MDMActionCell from '../components/tables/cells/MDMActionCell';
import Backend from '../../utils/Backend';

import NewParentItemModal from "./NewParentItemModal";

export default class ParentItemsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      error: null,
      successMessage: null,
      refreshData: false,
      showAddNew: false
    }
  }

  _renderError = () => {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div className="kt-alert kt-alert--outline alert alert-danger alert-dismissible" role="alert">
        <span>{error}</span>
      </div>
    )
  }

  _renderMessages = () => {
    let { successMessage } = this.state

    if (!successMessage) {
      return null
    }

    return (
      <div className="kt-alert kt-alert--outline alert alert-success alert-dismissible" role="alert">
        <span>{successMessage}</span>
      </div>
    )
  }

  _getColumns = () => {
    return [
      {
        Header: 'Value',
        accessor: 'value',
      },
      {
        Header: 'Entity',
        accessor: 'entity',
        Cell: rowInfo => {
          return (
            <>{rowInfo['value']? (rowInfo['value'] || "").charAt(0).toUpperCase() + (rowInfo['value'] || "").slice(1): ""}</>
          )
        }
      },
      {
        Header: 'Field',
        accessor: 'target_field',
        Cell: rowInfo => {
          return (
            <>{rowInfo['value'] ? (rowInfo['value'] || "").charAt(0).toUpperCase() + (rowInfo['value'] || "").slice(1): ""}</>
          )
        }
      },
      {
        Header: 'Parent (Make)',
        accessor: 'parent',
        Cell: rowInfo => {
          return (
            <>{rowInfo['value']}</>
          )
        }
      },
      {
        id: "active",
        Header: 'Actions',
        Cell: rowInfo => {
          return (
            <MDMActionCell
              item={rowInfo.original}
              onRefresh={item => {
                this.setState({ refreshData: false });
                Backend.refreshParentMappedItem(item);
                this.setState({ successMessage: 'Item is requested to refresh vehicles', refreshData: true });
              }}
            />
          )
        }
      }
    ]
  }

  render() {
    const columns = this._getColumns();

    return (<div className="row">
      <Card
        title="Parent Mapped Items"
        className="col-xl-12"
        renderToolbar={() => {
          return (
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                &nbsp;
                <div className="dropdown dropdown-inline">
                  <button
                    type="button"
                    className="btn btn-brand btn-icon-sm"
                    onClick={() => this.setState({ showAddNew: true, refreshData: false, successMessage: null })}
                  >
                    <i className="flaticon2-plus"></i> New Item
                  </button>
                </div>
              </div>
            </div>
          )
        }}
      >
        <div>
          {this._renderMessages()}
          {this._renderError()}

          <BaseTable
            endpoint={window.Api.MDMParentItems}
            columns={columns}
            refresh={this.state.refreshData}
          />
        </div>
      </Card>

      {this.state.showAddNew ? <NewParentItemModal show={true} onHide={(refresh) => {
        this.setState({ showAddNew: false });
        if (refresh) {
          this.setState({ refreshData: true, successMessage: 'Parent Item Added Successfully' });
        }
       }} /> : null}
    </div>
    )
  }
}
