import React from "react"

import Card from './components/cards/Card'
import CollectionsTable from './components/tables/CollectionsTable'
import Backend from '../utils/Backend'
import MarketingMessageTable from "./components/tables/MarketingMessageTable"

export default class MarketingMessages extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchTerm: '',
      statusFilters: [],
    }
  }


  render() {
    return (
      <div>
        <div className="row">
          <Card
            title={"Marketing Messages"}
            className="col-xl-12"
            renderToolbar={() => {
              return (
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    &nbsp;
                    <div className="dropdown dropdown-inline">
                      <button
                        type="button"
                        className="btn btn-brand btn-icon-sm"
                        onClick={() => this.props.history.push("/marketing-messages/create-message")}
                      >
                        <i className="flaticon2-plus"></i> New Message
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          >
            <MarketingMessageTable
              // onManageFiltersClick={message => {
              //   this.props.history.push(`/marketing-message/filters/${message.id}`)
              // }}
              onEditMarketingMessageClick={message => {
                this.props.history.push(`/marketing-message/${message.id}`)
              }}
              onDeleteMarketingMessageClick={message => {
                Backend.deleteMarketingMessage(message.id)
                  .then(() => { document.location.reload(); })
              }}
            />
          </Card>
        </div>
      </div>
    )
  }
}
