import React from 'react'

import BaseTable from './BaseTable'

import DealerActionsCell from './cells/DealerActionsCell'
import DealerActionSimiCell from './cells/DealerActionSimiCell'

import Backend from '../../../utils/Backend'
import DealerActionDeliveryCell from './cells/DealerActionDeliveryCell'
import DealerActionWhatsAppEnabledCell from './cells/DealerActionWhatsAppEnabledCell'
import DealerActionClickCollectCell from './cells/DealerActionClickCollectCell'
import DealerActionProspectsEnabledCell from './cells/DealerActionProspectsEnabledCell'

export default class DealersTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  _deactivate(dealer) {
    Backend.deactivateDealer(dealer)
    this.props.onDeactivatedDealer(dealer)
  }

  _activate(dealer) {
    Backend.activateDealer(dealer)
    this.props.onActivatedDealer(dealer)
  }

  _feature(dealer) {
    Backend.featureDealer(dealer)
  }

  _unfeature(dealer) {
    Backend.unfeatureDealer(dealer)
  }

  _getColumnWidth(accessor, headerText) {
    let { data } = this.state
    if (data == null) {
      return
    }
    const maxWidth = 400
    const magicSpacing = 10
    const cellLength = Math.max(
      ...data.map(row => (`${row[accessor]}` || '').length),
      headerText.length
    )
    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  _getColumns() {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Phone',
        accessor: 'phone'
      },
      {
        Header: 'Feed Source',
        accessor: 'source',
        width: this._getColumnWidth('source', 'Feed Source')
      },
      {
        Header: 'Active Listings #',
        accessor: 'active_vehicles_no',
        width: this._getColumnWidth('active_vehicles_no', 'Active Listings #')
      },
      {
        Header: 'Staff #',
        accessor: 'staff_no',
        width: this._getColumnWidth('staff_no', 'Staff #')
      },
      {
        Header: 'Location',
        accessor: 'location.state',
        width: this._getColumnWidth('location.state', 'Location')
      },
      {
        id: 'active',
        Header: 'Actions',
        width: 180,
        Cell: rowInfo => {
          return (
            <DealerActionsCell
              dealer={rowInfo.original}
              onDeactivateClick={dealer => this._deactivate(dealer)}
              onActivateClick={dealer => this._activate(dealer)}
              onEditClick={dealer => this.props.onEditDealerClick(dealer)}
            />
          )
        }
      }
    ]
  }

  render() {
    const columns = this._getColumns()
    return (
      <div>
        <BaseTable
          endpoint={window.Api.Dealers}
          columns={columns}
          onDataReceived={data => this.setState({ data })}
        />
      </div>
    )
  }
}
