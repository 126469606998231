import React from 'react'
import Switch from "react-switch";

export default class DealerActionWhatsAppEnabledCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      dealer: props.dealer
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }


  render() {
    let { dealer } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative'}}>
        <Switch
          height={20}
          width={40}
          checked={dealer.whatsapp_enabled}
          checkedIcon={null}
          uncheckedIcon={null}
          onColor="#5d78ff"
          onChange={checked => {
            dealer.whatsapp_enabled = checked
            this.props.onEditClick(dealer)
            this.setState({ dealer })
          }}
        />
      </span>
    )
  }
}
