import React from "react"

import MarketingMessageDetail from './components/MarketingMessageDetail'

import Backend from '../utils/Backend'

export default class EditMarketingMessage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      marketingMessage: null,
      loading: true,
    }
  }

  componentDidMount() {
    this._loadMarketingMessage()
  }

  _loadMarketingMessage() {
    let id = this.props.match.params.message_id
    if (!id) {
      this.setState({ loading: false })
      return
    }

    Backend.getMarketingMessage(id)
      .then(marketingMessage => {
        this.setState({
          marketingMessage,
          loading: false
        })
      })
  }

  _goToMarketingMessages() {
    this.props.history.push(`/marketing-messages`)
  }

  render() {
    let {
      marketingMessage,
      loading
    } = this.state

    if (loading) {
      return null
    }

    return (
      <MarketingMessageDetail
        marketingMessage={marketingMessage}
        goToMarketingMessages={() => this._goToMarketingMessages()}
      />
    )
  }
}
