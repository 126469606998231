import React from 'react'

import { CSVLink } from 'react-csv'

import ReactTable from 'react-table'
import 'react-table/react-table.css'
import FetchHelper from '../../../utils/FetchHelper'
import General from '../../../utils/General'

export default class BaseTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: [],
      pagesNo: 0,
      searchTerm: '',
      csvData: []
    }

    this.reactTable = React.createRef()
    this.csvLink = React.createRef()
  }

  _handleSearch = General.debounce(() => {
    let current = this.reactTable.current
    if (current) {
      this._fetchData(current.state, current)
    }
  }, 500)

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.refresh !== this.props.refresh) {
      let current = this.reactTable.current
      if (current) {
        this._fetchData(current.state, current)
      }
    }
  }

  _getUrl(endpoint, state) {
    let params
    if (endpoint.includes("marketing")) {
      params = {
        ...this.props.params,
        page_size: state.pageSize,
        page: state.page + 1,
        pagination_type: 'page',
      }
    } else {
      params = {
        ...this.props.params,
        page_size: state.pageSize,
        page: state.page + 1,
        pagination_type: 'page',
        search_term: this.state.searchTerm
      }
    }

    let sorted = state.sorted[0]
    if (sorted) {
      let orderBy = sorted.id
      orderBy = orderBy.replace(/\./g, '__')
      if (sorted.desc) {
        orderBy = `-${orderBy}`
      }
      params['order_by'] = orderBy
      console.log('99', orderBy)
    } else if (endpoint.includes("marketing")) {
      params['order_by'] = 'id'
    } else {
      params['order_by'] = 'created_at'
    }


    return this._addParams(endpoint, params)
  }

  _fetchData(state, instance) {
    this.setState({ loading: true })
    let url = this._getUrl(this.props.endpoint, state)

    console.log('dddd', state)

    FetchHelper.get(url)
      .then(response => {
        console.log('dddd', Math.ceil(response.count / state.pageSize))
        this.setState(
          {
            data: response.results,
            pagesNo: Math.ceil(response.count / state.pageSize),
            loading: false
          },
          () => this.props.onDataReceived(response.results)
        )
      })
      .catch(error => {
        console.log('Error', error)
      })
  }

  _addParams(url, params) {
    if (Object.keys(params).length === 0) {
      return url
    }

    // TODO: switch to an actual url helper here to avoid bugs/edge cases
    if (url.indexOf('?') === -1) {
      url += '?'
    } else if (!url.endsWith('&')) {
      url += '&'
    }

    Object.keys(params).forEach(function (key) {
      url += key + '=' + params[key] + '&'
    })

    // remove last '&'
    url = url.slice(0, -1)
    return url
  }

  _renderExportButtons() {
    if (
      this.reactTable == null ||
      this.reactTable.current == null ||
      this.props.getCSVData == null
    ) {
      return null
    }

    let csvData = this.state.csvData
    let current = this.reactTable.current

    return (
      <div className="row">
        <div className="col-md-12 text-right">
          <a
            className="btn btn-secondary"
            onClick={() => {
              let sortedData = current.getResolvedState().sortedData

              let csvData = this.props.getCSVData(sortedData)

              console.log('fff', sortedData)
              this.setState(
                {
                  csvData
                },
                () => {
                  // click the CSVLink component to trigger the CSV download
                  this.csvLink.current.link.click()
                }
              )
            }}
          >
            Export to CSV
          </a>
          <CSVLink
            data={csvData}
            filename="data.csv"
            className="hidden"
            ref={this.csvLink}
            target="_blank"
          />
        </div>
      </div>
    )
  }

  render() {
    const { data, pagesNo, loading } = this.state
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="dataTables_filter">
              <label>
                {this.props.label}
                <input
                  type="search"
                  className="form-control form-control-sm"
                  placeholder={this.props.placeholder}
                  onChange={e => {
                    this.setState(
                      {
                        searchTerm: e.target.value
                      },
                      () => this._handleSearch()
                    )
                  }}
                />
              </label>
            </div>
          </div>
          <div className="col-md-6 pull-right">{this._renderExportButtons()}</div>
        </div>
        <ReactTable
          ref={this.reactTable}
          manual
          data={data}
          pages={pagesNo}
          loading={loading}
          onFetchData={this._fetchData.bind(this)}
          columns={this.props.columns}
          loading={this.props.isLoading}
          getTdProps={this.props.getTdProps}
          getTrProps={this.props.getTrProps}
          getTheadProps={this.props.getTheadProps}
          getTheadThProps={this.props.getTheadThProps}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
        />
      </div>
    )
  }
}

BaseTable.defaultProps = {
  params: {},
  label: 'Search:',
  showPaginationTop: true,
  showPaginationBottom: true,
  getTdProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingLeft: 10
      }
    }
  },
  getTrProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingTop: 10,
        paddingBottom: 10
      }
    }
  },
  getTheadProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'left'
      }
    }
  },
  getTheadThProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        textAlign: 'left',
        paddingLeft: 10
      }
    }
  },
  onDataReceived: () => { }
}
