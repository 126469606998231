import React from "react"

import AdvertDetail from './components/AdvertDetail'

import Backend from '../utils/Backend'

export default class EditAdvert extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      advert: null,
      loading: true,
    }
  }

  componentDidMount(){
    this._loadAdvert()
  }

  _loadAdvert(){
    let id = this.props.match.params.advert_id
    if(!id){
      this.setState({ loading: false })
      return
    }

    Backend.getAdvert(id)
    .then(advert => {
      this.setState({
        advert,
        loading: false
      })
    })
  }

  _goToAdverts(){
    this.props.history.push(`/adverts`)
  }

  render() {
    let {
      advert,
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <AdvertDetail
        advert={advert}
        goToAdverts={() => this._goToAdverts()}
      />
    )
  }
}
