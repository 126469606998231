import React from 'react'

import BaseTable from './BaseTable'

import ScreenActionsCell from './cells/ScreenActionsCell'
import DateDisplayCell from './cells/DateDisplayCell'

export default class ScreensTable extends React.Component {
  _getColumns() {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 200
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 70
      },
      {
        Header: 'Updated at',
        accessor: 'updated_at',
        Cell: rowInfo => {
          return <DateDisplayCell dateValue={rowInfo['value']} />
        }
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: rowInfo => {
          return <DateDisplayCell dateValue={rowInfo['value']} />
        }
      },
      {
        id: 'active',
        Header: 'Actions',
        width: 230,
        Cell: rowInfo => {
          return <ScreenActionsCell
            screen={rowInfo.original}
            onManageSectionsClick={screen => this.props.onManageSectionsClick(screen)}
            onEditClick={screen => this.props.onEditScreenClick(screen)}
            onDeleteClick={screen => this.props.onDeleteScreenClick(screen)}
          />  
        }
      }
    ]
  }

  render() {
    const columns = this._getColumns()
    return (
      <div>
        <BaseTable
          endpoint={window.Api.Screens}
          columns={columns}
          onDataReceived={data => {
            this.setState({ data })
          }}
        />
      </div>
    )
  }
}
