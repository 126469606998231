import React from "react"

import Div from './layouts/Div'
import MySelect from './common/MySelect'

import Backend from '../../utils/Backend'
import ColorPicker from "./common/ColorPicker"
import { components } from "react-select"

export default class SectionDetail extends React.Component {
  constructor(props){
    super(props)

    let addMode = props.section == null

    this.state = {
      addMode,
      componentOptions: [],
      section: props.section || { name: "", description: "", status: "" },
    }
  }


  _isValid(data){
    let error = null;
    const {
      name,
      description,
      status,
      content_type,
      component_id,
    } = data;

    if (name === "") error = "Enter name please!";
    if (description === "") error = "Enter description please!";
    if (status === "") error = "Select status please!";
    // if (content_type === "") error = "Select Content Type please!";
    // if (component_id === "") error = "Select Component please!";

    if (error) {
      alert(error);
      return false;
    }

    return true
  }

  _handleSave(){
    let {
      section
    } = this.state

    if(!this._isValid(section)){
      return
    }

    this._save()
  }

  _save(){
    let {
      section,
      addMode
    } = this.state

    this.setState({loading: true})

    this._updateBackend(addMode, section)
    .then(section => {
      this.setState({
        section,
        loading: false,
        addMode: false
      })
      this.props.goToSections()
    })
    .catch(error => {
      alert(error.message)
      this.setState({loading: false})
    })
  }

  _updateBackend(addMode, section){
    if(addMode) return Backend.createSection(section)
    else {
      const modifiedSection = {...section };
      delete modifiedSection.components;
      return Backend.editSection(modifiedSection);
    }
  }

  _getSectionsStatuses() {
    return [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" }
    ]
  }

  _getContentTypes() {
    return [
      { value: "Collection_Sets", label: "Collection Set" },
      { value: "Collections", label: "Collection" },
      { value: "Recommendation", label: "Recommendation" }
    ]
  }

  // _createComponentOptions = (data) => {
  //   const componentOptions = [];
  //   data.forEach((option) => {
  //     componentOptions.push({
  //       value: option.id, label: option.name 
  //     });
  //   });
  //   this.setState({ componentOptions });
  // }

  // _onSelectContentType(e) {
  //   let { section } = this.state;
  //   section.content_type = e.target.value;
  //   this.setState({
  //     section,
  //     component_id: "",
  //     componentOptions: []
  //   });
    
  //   switch(section.content_type) {
  //     case "Collection_Sets":
  //       Backend.getAllCollectionSets()
  //       .then(({ results }) => this._createComponentOptions(results))
  //       .catch(error => alert(error.message))
  //     case "Collections":
  //       return "AAAA";
  //   }  
  // }

  // _renderComponentSelector() {
  //   let { section, componentOptions } = this.state;
  //   if (componentOptions.length > 0) {
  //     return (
  //       <div className="form-group">
  //         <label>Component</label>
  //         <MySelect
  //           value={section.component_id}
  //           options={componentOptions}
  //           onChangeSelect={(e) => {
  //             section.component_id = e.target.value;
  //             this.setState({
  //               section
  //             });
  //           }}
  //         />
  //       </div>
  //     );
  //   }
  // }

  render() {
    let {
      addMode,
      section,
      loading
    } = this.state

    let title = addMode ? "Save" : "Update"


    return (
      <div className="row">
				<div className="col-md-6">
					<Div className="kt-portlet" disabled={loading}>
						<div className="kt-portlet__head">
							<div className="kt-portlet__head-label">
								<h3 className="kt-portlet__head-title">
                  Section
								</h3>
							</div>
						</div>


						<form className="kt-form">
							<div className="kt-portlet__body">
								<h5 className="kt-section__title">Details:</h5><br/>

								<div className="form-group">
									<label>Name</label>
									<input type="text" className="form-control"
                    value={section.name}
                    onChange={e => {
                      section.name = e.target.value
                      this.setState({ section })
                    }}
                    placeholder="Name"
                  />
								</div>

                <div className="form-group">
                  <label>Description</label>
                  <textarea value={section.description} onChange={e => {
                    section.description = e.target.value
                    this.setState({ section })
                  }} className="form-control" placeholder="Description" />
                </div>

                <div className="form-group">
                  <label>Status</label>
                  <MySelect
                    value={section.status}
                    options={this._getSectionsStatuses()}
                    onChangeSelect={(e) => {
                      section.status = e.target.value;
                      this.setState({
                        section
                      });
                    }}
                  />
                </div>

                {/* <div className="form-group">
                  <label>Content Type</label>
                  <MySelect
                    value={section.content_type}
                    options={this._getContentTypes()}
                    onChangeSelect={(e) => {this._onSelectContentType(e)}}
                  />
                </div>
                
                {this._renderComponentSelector()} */}
							</div>

							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<button type="reset" onClick={() => this._handleSave()} className="btn btn-primary">
                    { title }
                  </button>
								</div>
							</div>
						</form>
					</Div>
				</div>
			</div>
    )
  }
}
