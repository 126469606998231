import React from "react"
import Switch from "react-switch";

export default class ColorPicker extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      title: props.title,
      value: props.value
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }


  render() {
    let {
      title,
      value,
    } = this.state


    return (
      <>
        <div className="form-group">
          <label className="d-flex justify-content-between">
            <span><label>{ title }</label></span>
            <Switch
              height={14}
              width={28}
              checked={value != null}
              checkedIcon={null}
              uncheckedIcon={null}
              onColor="#5d78ff"
              onChange={checked => {
                if(checked){
                  this.props.onChange("#fff")
                }
                else{
                  this.props.onChange(null)
                }
              }}
            />
          </label>
        </div>
        { value &&
          <div className="form-group">
            <input type="color" className="form-control"
              value={value}
              onChange={e => {
                this.props.onChange(e.target.value)
              }}
            />
          </div>
        }
      </>
    )
  }
}
