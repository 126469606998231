import React from "react"

//  import StatCard from './components/cards/StatCard'
import Card from './components/cards/Card'
import InsuranceTable from './components/tables/InsuranceTable'

// import Backend from '../utils/Backend'

export default class Insurance extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false, // CHANGE TO TRUE
      searchTerm: '',
      statusFilters: [],
    }
  }

  // componentDidMount(){
  //   this._loadStats()
  // }

  // _loadStats(){
  //   Backend.getAdvertStats()
  //   .then(stats => {
  //     this.setState({
  //       stats,
  //       loading: false
  //     })
  //   });
  // }

  render() {
    let {
      // stats,
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <div>
        {/* <div className="row">
          <StatCard
            title="Impressions"
            value={ stats.total.impressions }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          <StatCard
            title="Clicks"
            value={ stats.total.clicks }
            size="col-md-3 col-lg-3 col-xl-3"
          />
  			</div> */}

        <div className="row">
          <Card
            title="Insurance"
            className="col-xl-12"
            renderToolbar={() => {
              return (
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    &nbsp;
                    <div className="dropdown dropdown-inline">
                      <button
                        type="button"
                        className="btn btn-brand btn-icon-sm"
                        onClick={() => this.props.history.push("/create-insurance")}
                      >
                        <i className="flaticon2-plus"></i> New Insurance
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          >
            <InsuranceTable
              onEditInsuranceClick={insurance => {
                this.props.history.push(`/insurance/${insurance.id}`)
              }}
            />
          </Card>
        </div>
      </div>
    )
  }
}
