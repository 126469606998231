import React from 'react'

export default class CollectionSetActionsCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collection_set: props.collection_set
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderManage(collection_set) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-primary btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onManageCollectionsClick(collection_set)
        }}
      >
        Collections
      </a>
    )
  }

  _renderEdit(collection_set) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-primary btn-bold"
        style={{ marginLeft: '10px'}}
        onClick={e => {
          e.preventDefault()
          this.props.onEditClick(collection_set)
        }}
      >
        Edit
      </a>
    )
  }

  _renderDelete(collection_set) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-danger btn-bold"
        style={{ marginLeft: '10px'}}
        onClick={e => {
          e.preventDefault()
          this.props.onDeleteClick(collection_set)
        }}
      >
        Delete
      </a>
    )
  }

  render() {
    let { collection_set } = this.state
    return (
      <span
        style={{ overflow: 'visible', position: 'relative', width: '110px' }}
      >
        {this._renderManage(collection_set)}
        {this._renderEdit(collection_set)}
        {this._renderDelete(collection_set)}
      </span>
    )
  }
}
