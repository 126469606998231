import React from 'react'

export default class MDMActionCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      item: props.item
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderRefresh(item) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="#"
        className="btn btn-sm btn-primary btn-bold"
        style={{ marginLeft: '10px' }}
        onClick={e => {
          e.preventDefault()
          this.props.onRefresh(item)
        }}
      >
        Refresh
      </a>
    )
  }

  _renderDelete(item) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="#"
        className="btn btn-sm btn-danger btn-bold"
        style={{ marginLeft: '10px' }}
        onClick={e => {
          e.preventDefault()
          this.props.onDelete(item)
        }}
      >
        Delete
      </a>
    )
  }

  render() {
    let { item } = this.state;
    return (
      <span style={{ overflow: 'visible', position: 'relative', width: '110px' }}>
        {this._renderRefresh(item)}
        {this.props.onDelete && this._renderDelete(item)}
      </span>
    )
  }
}
