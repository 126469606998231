import React from 'react'

import Backend from '../../utils/Backend'
import BaseTable from '../components/tables/BaseTable'

import ParentItemModal from "./ParentItemModal";

export default class UnmappedItemsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedRecords: [],
      loading: false,
      error: null,
      successMessage: null,
      refreshData: false,
      showParentItemModal: false
    }
  }

  onRecordSelect = (id, selected) => {
    let selectedRecs = [...this.state.selectedRecords];
    if (selected) {
      selectedRecs.push(id);
    } else {
      selectedRecs = selectedRecs.filter(i => i !== id);
    }
    this.setState({ 'selectedRecords': selectedRecs });
  }

  addAs = (type, parentItem) => {
    this.setState({ loading: true, refreshData: false });

    Backend.addAsParentOrChild(this.state.selectedRecords, type, parentItem)
      .then(res => {
        this.setState({
          loading: false,
          successMessage: 'Selected items are mapped successfully',
          refreshData: true
        });
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  _renderError = () => {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div className="kt-alert kt-alert--outline alert alert-danger alert-dismissible" role="alert">
        <span>{error}</span>
      </div>
    )
  }

  _renderMessages = () => {
    let { successMessage } = this.state

    if (!successMessage) {
      return null
    }

    return (
      <div className="kt-alert kt-alert--outline alert alert-success alert-dismissible" role="alert">
        <span>{successMessage}</span>
      </div>
    )
  }

  _getColumns = () => {
    const selectedRecs = (this.state.selectedRecords || []);
    return [
      {
        Header: '',
        accessor: 'id',
        width: 100,
        Cell: rowInfo => {
          const id = rowInfo['value'];
          return <div className='d-flex align-items-center justify-content-center'>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" checked={selectedRecs.includes(id)} name={`record-${id}`} id={`record-${id}`}
                className='custom-control-input'
                onChange={(e) => this.onRecordSelect(id, e.target.checked)} />
              <label className="custom-control-label custom-control-label-lg" htmlFor={`record-${id}`}>&nbsp;&nbsp;</label>
            </div>
          </div>
        }
      },
      {
        Header: 'Value',
        accessor: 'value',
      },
      {
        Header: 'Entity',
        accessor: 'entity',
        Cell: rowInfo => {
          return (
            <>{(rowInfo['value'] || "").charAt(0).toUpperCase() + (rowInfo['value'] || "").slice(1)}</>
          )
        }
      },
      {
        Header: 'Field',
        accessor: 'target_field',
        Cell: rowInfo => {
          return (
            <>{(rowInfo['value'] || "").charAt(0).toUpperCase() + (rowInfo['value'] || "").slice(1)}</>
          )
        }
      },
      {
        Header: 'Parent (Make)',
        accessor: 'parent',
        Cell: rowInfo => {
          return (
            <>{rowInfo['value']}</>
          )
        }
      },
    ]
  }

  render() {
    const columns = this._getColumns()
    return (
      <div>
        {this._renderMessages()}
        {this._renderError()}

        <div className='row mb-3'>
          <div className='col col-lg-6'>
            <button type='button' className='btn btn-sm btn-label-primary btn-bold'
              disabled={!this.state.selectedRecords.length} onClick={() => this.addAs('parent')}>Add as Parent Item</button>
            <button type='button' className='btn btn-sm btn-label-info btn-bold ml-2'
              disabled={!this.state.selectedRecords.length} onClick={() => {
                this.setState({ showParentItemModal: true });
              }}>Add as Child Item</button>
          </div>
        </div>
        <BaseTable
          endpoint={window.Api.MDMUnmappedItems}
          columns={columns}
          refresh={this.state.refreshData}
          onDataReceived={data => this.setState({ data })}
        />

        {this.state.showParentItemModal ? <ParentItemModal show={true} onSelect={(item) => {
          this.setState({ showParentItemModal: false });
          this.addAs('child', item);
        }} onHide={() => {
          this.setState({ showParentItemModal: false });
        }} /> : null}

      </div>
    )
  }
}
