import React from 'react'
import Modal from 'react-bootstrap/Modal'

import BaseTable from '../components/tables/BaseTable'

export default class ParentItemModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: props.show,
      key: null,
      parentItem: null,
      parentItemRecord: null,
      searchTerm: '',
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _submit() {
    let { parentItem, parentItemRecord } = this.state
    this.props.onSelect(parentItem, parentItemRecord);
  }

  _getColumns = (setParent, selectedParent) => {
    return [
      {
        Header: '',
        accessor: 'id',
        width: 100,
        Cell: rowInfo => {
          const id = rowInfo['value'];
          return <div className='d-flex align-items-center justify-content-center'>
            <div className="custom-control custom-radio">
              <input type="radio" checked={selectedParent && id && selectedParent + '' === id + ''} name={`parent-record-${id}`} 
                id={`parent-record-${id}`}
                className='custom-control-input'
                onChange={(e) => setParent(id, rowInfo['original'])} />
              <label className="custom-control-label custom-control-label-lg" htmlFor={`parent-record-${id}`}>&nbsp;&nbsp;</label>
            </div>
          </div>
        }
      },
      {
        Header: 'Value',
        accessor: 'value',
      },
      {
        Header: 'Entity',
        accessor: 'entity',
        Cell: rowInfo => {
          return (
            <>{rowInfo && rowInfo['value'] ? rowInfo['value'].charAt(0).toUpperCase() + rowInfo['value'].slice(1): ''}</>
          )
        }
      },
      {
        Header: 'Field',
        accessor: 'target_field',
        Cell: rowInfo => {
          return (
            <>{rowInfo && rowInfo['value'] ? rowInfo['value'].charAt(0).toUpperCase() + rowInfo['value'].slice(1) : ''}</>
          )
        }
      },
      {
        Header: 'Parent (Make)',
        accessor: 'parent',
        Cell: rowInfo => {
          return (
            <>{rowInfo['value']}</>
          )
        }
      },
    ]
  }

  _setParent = (id, record) => {
    this.setState({ parentItem: id, parentItemRecord: record });
  }


  render() {
    let { show } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Parent Item</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <BaseTable
              endpoint={`${window.Api.MDMParentItems}`}
              columns={this._getColumns(this._setParent, this.state.parentItem)}
              showPaginationTop={false}
              {...{getTdProps: (state, rowInfo, column, instance) => {
                return {
                  style: {
                    paddingLeft: 5
                  }
                }
              },
              getTrProps: (state, rowInfo, column, instance) => {
                return {
                  style: {
                    paddingTop: 5,
                    paddingBottom: 5
                  }
                }
              },
              getTheadProps: (state, rowInfo, column, instance) => {
                return {
                  style: {
                    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
                    paddingTop: 5,
                    paddingBottom: 5,
                    textAlign: 'left'
                  }
                }
              },
              getTheadThProps: (state, rowInfo, column, instance) => {
                return {
                  style: {
                    textAlign: 'left',
                    paddingLeft: 5
                  }
                }
              }}}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}>Cancel</button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={!this.state.parentItem}
            onClick={() => this._submit()}>Submit</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
