import React from 'react'
import Modal from 'react-bootstrap/Modal'
import MySelect from '../common/MySelect';
import Select from 'react-select';

import Backend from '../../../utils/Backend'
import FetchHelper from '../../../utils/FetchHelper'


export default class AddFilterModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: props.show,
      loading: false,
      collection: null,

      type_options: [],
      input_type: null,
      filter_type: "",
      key: "",

      value: "",

      options: [],
      multiValue: [],

      modelsOptions: [],
      modelsValues: [],
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  componentDidMount() {
    this.setState({ type_options: this._getTypeOptions() })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.input_type !== "text" && this.state.key !== prevState.key) {
      if (this.state.key === "seller_type") {
        const options = [
          { label: "Dealership", value: "dealer" },
          { label: "Private seller", value: "private" },
        ];
        this.setState({ options });
      }
      else {
        FetchHelper.get(window.Api.Base + "/" + this.state.filter_type).then((data) => {
          this._processFetchedData(data);
        })
      }
    }

    if (this.state.show !== prevProps.show) {
      this.setState({ filter_type: '', key: '', value: '', multiValue: [], modelsValues: [] });
    }

    if (JSON.stringify(this.state.collection) !== JSON.stringify(prevState.collection)) {
      this.setState({ type_options: this._getTypeOptions() });
    }
  }

  _processFetchedData({ results }, models = false) {
    let options = [];
    if (typeof results[0] === 'object') {
      results.forEach(value => {
        options.push({
          label: value.name ? value.name : value.type,
          value: value.id
        })
      })
    }
    else {
      results.forEach(value => {
        options.push({ label: value, value: value })
      })
    }
    if (models) this.setState({ modelsOptions: options });
    else this.setState({ options });
  }

  _getTypeOptions() {
    let allFilters = [
      { label: "min year", value: "min_year", input_type: "text" },
      { label: "max year", value: "max_year", input_type: "text" },
      { label: "min price", value: "min_price", input_type: "text" },
      { label: "max price", value: "max_price", input_type: "text" },
      { label: "mileage", value: "max_mileage", input_type: "text" },
      { label: "min engine", value: "min_engine_size", input_type: "text" },
      { label: "max engine", value: "max_engine_size", input_type: "text" },
      { label: "vehicle registration", value: "vehicle_registration", input_type: "text" },

      { label: "manufacturers", value: "manufacturer_ids", input_type: "multi_select" },
      { label: "bodies", value: "body_ids", input_type: "multi_select" },
      { label: "transmissions", value: "transmission_ids", input_type: "multi_select" },
      { label: "fuels", value: "fuel_type_ids", input_type: "multi_select" },
      { label: "seller type", value: "seller_type", input_type: "multi_select" },
      { label: "colors", value: "colors", input_type: "multi_select" },
      { label: "locations", value: "location_states", input_type: "multi_select" },
    ];

    let { collection } = this.state;
    
    if (collection && collection.filters) {
      const collectionFilters = collection.filters.map((f) => f.key);
      allFilters = allFilters.filter((filter) => !collectionFilters.includes(filter.value));
    }
    return allFilters;
  }

  _backendCall(filter_type, key, value) {
    const filter = { filter_type, key, value };
    Backend.createFilter(filter)
      .then((data) => {
        let { collection } = this.state;
        collection.filters.push(data.id);

        collection = { ...collection, filters: collection.filters.map((filter) => (typeof filter === 'object' && filter !== null ? filter.id : filter)) };

        this.setState({ collection });

        Backend.editCollection(collection)
          .then(() => {
            this.setState({ loading: false })
            this.props.onSubmit()
          })
          .catch(error => {
            this.setState({ loading: false })
            alert(error.message)
            this.props.onHide();
          });
      })
      .catch(error => {
        this.setState({ loading: false })
        alert(error.message)
        this.props.onHide()
      })
  }

  _submit() {
    let {
      filter_type, input_type, key,
      value, multiValue, modelsValues
    } = this.state
    if (key === "") {
      alert("Please Select a Type of Filter")
      return
    }

    if (input_type === "text") {
      if (value === "") {
        alert("Please Enter a Value")
        return
      }

      this._backendCall(filter_type, key, value);
    }

    if (input_type === "multi_select") {
      if (multiValue.length < 1) {
        alert("Please Select Options");
        return;
      }
      value = this._stringifyValues(multiValue);

      if (modelsValues.length > 0) {
        let modelsString = this._stringifyValues(modelsValues);
        this._backendCall("models", "model_ids", modelsString);
      } else {
        this._backendCall(filter_type, key, value);
      }
    }

    this.setState({ loading: true })
  }

  _stringifyValues(values) {
    let str = "";
    values.forEach(value => {
      str += value.value + ","
    });
    str = str.substring(0, str.length - 1)
    str.replace(",", "%2C");
    return str;
  }

  _handleMultiChange = (options) => {
    this.setState({ multiValue: options });
    if (this.state.filter_type === "manufacturers") {
      const ids = this._stringifyValues(options);
      FetchHelper.get(`${window.Api.Base}/models?manufacturer_ids=${ids}`).then(data => {
        this._processFetchedData(data, true);
      })
    }
  }

  _renderMultiSelect = () => {
    return (
      <>
        <div className="form-group mt-3">
          <label>Select Options</label>
          <Select
            name="Filters"
            placeholder="Select values"
            value={this.state.multiValue}
            options={this.state.options}
            onChange={this._handleMultiChange}
            isMulti={true}
          />
        </div>
        {this.state.filter_type === "manufacturers"
          ? <div className="form-group mt-3">
            <label>Select Models</label>
            <Select
              name="Moldes"
              placeholder="Select Models"
              value={this.state.modelsValues}
              options={this.state.modelsOptions}
              onChange={options => { this.setState({ modelsValues: options }); }}
              isMulti={true}
            />
          </div>
          : null
        }
      </>
    )
  }

  _renderInput() {
    switch (this.state.input_type) {
      case null:
        return <p>Please Select Type of Filter</p>
      case "text":
        return (
          <div className="form-group mt-3">
            <label>Value</label>
            <input
              type="text"
              className="form-control"
              onChange={e => {
                let value = e.target.value
                this.setState({ value })
              }}
            />
          </div>
        )
      case "multi_select":
        return this._renderMultiSelect()
    }
  }

  _onChangeType = e => {
    const index = e.target.selectedIndex;
    const filter_type = e.target[index].text.toLowerCase();
    const key = e.target.value;

    let input_type = "";
    this.state.type_options.forEach(option => {
      if (key === option.value) input_type = option.input_type;
    });

    this.setState({ input_type, filter_type, key, value: "", multiValue: [], modelsOptions: [], modelsValues: [], });
  }

  render() {
    let {
      show,
      loading,
      key,
      type_options
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group mt-3">
            <label>Type</label><br />
            <MySelect
              value={key}
              options={type_options}
              onChangeSelect={this._onChangeType}
            />
          </div>

          {this._renderInput()}
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            disabled={loading}
            onClick={() => this.props.onHide()}>Cancel</button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={() => this._submit()}>{loading ? 'Saving...' : 'Submit'}</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
