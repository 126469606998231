import React from "react"

import Card from './components/cards/Card'
import SkupeNetDealersTable from './components/tables/SkupeNetDealersTable'

import AddSkupeNetDealerModal from './components/modal/AddSkupeNetDealerModal'

export default class SkupeNetDealers extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      showAddModal: false
    }
  }


  render() {
    let {
      showAddModal
    } = this.state
    return (
      <div>
        <div className="row">
          <Card
            title="Skupenet Dealers"
            className="col-xl-12"
            renderToolbar={() => {
              return (
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">&nbsp;
                    <button
                      type="button"
                      className="btn btn-brand btn-icon-sm"
                      onClick={() => this.setState({ showAddModal: true})}
                    >
                      <i className="flaticon2-plus"></i>
                      Skupenet Dealer
                    </button>
                  </div>
                </div>
              )
            }}
          >
            <SkupeNetDealersTable/>
          </Card>
          <AddSkupeNetDealerModal
            show={showAddModal}
            onSubmit={(name, key) => {
              this.setState({ showAddModal: false })
              window.location.reload(false); 
            }}
            onHide={() => this.setState({ showAddModal: false })}
          />
        </div>
      </div>
    )
  }
}
