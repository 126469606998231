import React from "react"

import Card from './components/cards/Card'
import BodiesTable from './components/tables/BodiesTable'

export default class Bodies extends React.Component {
  constructor(props){
    super(props)

    this.state = {
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <Card
            title="Segments"
            className="col-xl-12"
          >
            <BodiesTable/>
          </Card>
        </div>
      </div>
    )
  }
}

Bodies.defaultProps = {
  dealerMode: true
}
