import React from 'react'

import ImageEdit from '../../common/ImageEdit'

import Backend from '../../../../utils/Backend'

export default class BodyActionsCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      body: props.body
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _updateBody(body){
    Backend.updateBody(body)
    .then(body => {

    })
    .catch(error => {
      alert(error.message)
    })
  }

  render() {
    let { body } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative', width: '110px', marginTop: 20}}>
        <ImageEdit
          image={body.image}
          containerStyle={{ margin: 0, marginTop: 10 }}
          resizeMode="contain"
          onUpdated={image => {
            body.image = image
            this.setState({ body })
            this._updateBody(body)
          }}
        />
      </span>
    )
  }
}
