import React from "react"

import Card from './components/cards/Card'
import CollectionsTable from './components/tables/CollectionsTable'
import Backend from '../utils/Backend'

export default class Collections extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      searchTerm: '',
      statusFilters: [],
    }
  }


  render() {
    return (
      <div>
        <div className="row">
          <Card
            title={"Collections"}
            className="col-xl-12"
            renderToolbar={() => {
              return (
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    &nbsp;
                    <div className="dropdown dropdown-inline">
                      <button
                        type="button"
                        className="btn btn-brand btn-icon-sm"
                        onClick={() => this.props.history.push("/collection/create-collection")}
                      >
                        <i className="flaticon2-plus"></i> New Collection
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          >
            <CollectionsTable
              onManageFiltersClick={collection => {
                this.props.history.push(`/collection/filters/${collection.id}`)
              }}
              onEditCollectionClick={collection => {
                this.props.history.push(`/collection/collection/${collection.id}`)
              }}
              onDeleteCollectionClick={collection => {
                Backend.deleteCollection(collection.id)
                .then(() => {document.location.reload();})
              }}
            />
          </Card>
        </div>
      </div>
    )
  }
}
