import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from '../../../utils/Backend'
import MySelect from '../common/MySelect'

export default class AddSectionToScreenModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      selected_section: {id: ""},
      sections: [],
      screens: null,
      loading: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _submit(){
    let {
      selected_section
    } = this.state

    if (selected_section.id === "") {
      alert("Please select a Section")
      return
    }
    
    this.setState({ loading: true })
    let { screen } = this.state;
    for (let section = 0; section < screen.sections.length; ++section) {
      screen.sections[section].section = screen.sections[section].section.id;
    }
    
    screen.sections.push({
      section: selected_section.id,
      order: 1
    });
    Backend.editScreen(screen)
    .then(() => {
      this.setState({ loading: false })
      this.props.onSubmit()
    }).catch(error => {
      this.setState({ loading: false })
      alert(error.message)
      this.props.onHide()
    })
  }

  _getSectionsOptions() {
    return this.state.sections.map((section) => {
      return {
        label: section.name, value: section.id
      }
    });
  }

  render() {
    let {
      show,
      loading,
      sections,
      selected_section
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Section</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group mt-3">
            <label>Section</label><br/>
            <MySelect
              value={selected_section.id}
              options={this._getSectionsOptions()}
              onChangeSelect={(e) => {
                for (let section of sections) {
                  if (e.target.value === section.id) {
                    this.setState({selected_section: section})
                    break;
                  }
                }
              }}
            />
          </div>
        </Modal.Body>

        { !loading &&
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}>Cancel</button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this._submit()}>Submit</button>
        </Modal.Footer>
        }
      </Modal>
    )
  }
}
