import React from "react"

import SectionDetail from './components/SectionDetail'

import Backend from '../utils/Backend'

export default class EditSection extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      section: null,
      loading: true,
    }
  }

  componentDidMount(){
    this._loadSection();
  }

  _loadSection(){
    let id = this.props.match.params.section_id
    if(!id){
      this.setState({ loading: false })
      return
    }

    Backend.getSection(id)
    .then(section => {
      this.setState({
        section,
        loading: false
      })
    })
  }

  _goToSections(){
    this.props.history.push(`/cms/sections`)
  }

  render() {
    let {
      section,
      loading
    } = this.state

    if (loading) {
      return null
    }

    return (
      <SectionDetail
        section={section}
        goToSections={() => this._goToSections()}
      />
    )
  }
}
