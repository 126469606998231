import React from "react"

import BaseTable from "./BaseTable"

import AdvertActionsCell from "./cells/AdvertActionsCell"

import Backend from '../../../utils/Backend'

export default class AdvertsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }
  }

  _deactivate(advert){
    Backend.deactivateAdvert(advert)
    this.props.onDeactivatedAdvert(advert)
  }

  _activate(advert){
    Backend.activateAdvert(advert)
    this.props.onActivatedAdvert(advert)
  }

  _getColumns() {
    return [
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: 'CTA',
        accessor: 'cta_text'
      },
      {
        Header: 'Impressions',
        accessor: 'impressions'
      },
      {
        Header: 'Clicks',
        accessor: 'clicks'
      },
      {
        id: "active",
        Header: 'Actions',
        Cell: rowInfo => {
          return (
            <AdvertActionsCell
              advert={rowInfo.original}
              onDeactivateClick={advert => this._deactivate(advert)}
              onActivateClick={advert => this._activate(advert)}
              onEditClick={advert => this.props.onEditAdvertClick(advert)}
            />
          )
        }
      }
    ]
  }

  render() {
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          endpoint={window.Api.Adverts}
          columns={columns}
        />
      </div>
    )
  }
}
