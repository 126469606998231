import React from "react"

export default class StatCard extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      title: props.title,
      value: props.value,
      size: props.size
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      title,
      value,
      size
    } = this.state

    return (
      <div className={ size }>
        <div className="kt-portlet">
          <div className="kt-portlet__body  kt-portlet__body--fit">
            <div className="kt-widget24">
              <div className="kt-widget24__details">
                <div className="kt-widget24__info">
                  <h4 className="kt-widget24__title">
                    { title }
                  </h4>
                </div>
              </div>
              <div className="kt-widget24__details">
                <span className="kt-widget24__stats kt-font-brand">
                   { value }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
