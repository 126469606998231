import React from "react"

import 'flatpickr/dist/flatpickr.min.css'
import Div from './layouts/Div'

import ImageEdit from './common/ImageEdit'

import Backend from '../../utils/Backend'

export default class InsuranceDetail extends React.Component {
  constructor(props){
    super(props)

    let addMode = props.insurance == null

    this.state = {
      addMode,
      insurance: props.insurance || { }
    }
  }

  _isValid(data){
    let error = null;


    if (!data.insurer_name) {
      error = "You must specify insurer name";
    }
    else if (!data.logo_url) {
      error = ("You must upload the logo");
    }
    else if (!data.cta_title) {
      error = "You must specify a cta title";
    }
    else if (!data.cta_subtitle) {
      error = "You must include a cta subtitle";
    }
    else if (!data.cta_link){
      error = "You must include a cta link";
    }

    if(error){
      alert(error);
      return false;
    }

    return true
  }

  _handleSave(){
    let {
      insurance
    } = this.state

    if(!this._isValid(insurance)){
      return
    }

    this._save()
  }

  _save(){
    let {
      insurance,
      addMode
    } = this.state

    this.setState({loading: true})

    this._updateBackend(addMode, insurance)
    .then(insurance => {
      this.setState({
        insurance,
        loading: false,
        addMode: false
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({loading: false})
    })
  }

  _updateBackend(addMode, insurance){
    if(addMode){
      return Backend.createInsurance(insurance)
    }
    else{
      return Backend.editInsurance(insurance)
    }
  }

  // _renderPreviewCardStack(advert, cover, logo){
  //   if(advert.placement !== "all" && advert.placement !== "card_stack"){
  //     return
  //   }

  //   return (
  //     <div class="advert_preview_card_stack advert_preview_background"
  //       style={{
  //         backgroundColor: advert.background_color,
  //         background: `url("${cover}") no-repeat`,
  //         backgroundSize: 'cover'
  //       }}
  //     >
  //       <h3 className="advert_preview_title"
  //         style={{
  //           color: advert.title_color,
  //         }}
  //       >
  //         {advert.title}
  //       </h3>
  //       <button
  //         href={advert.cta_link}
  //         className="advert_preview_cta"
  //         style={{
  //           color: advert.cta_text_color,
  //           background: advert.cta_background_color
  //         }}
  //       >
  //         { advert.cta_text }
  //       </button>
  //       <img className="advert_preview_logo" src={logo}/>
  //     </div>
  //   )
  // }

  // _renderPreviewBanner(advert, cover, logo){
  //   if(advert.placement !== "all" && advert.placement !== "banner"){
  //     return
  //   }

  //   return (
  //     <div class="advert_preview_banner advert_preview_background"
  //       style={{
  //         backgroundColor: advert.background_color,
  //         background: `url("${cover}") no-repeat`,
  //         backgroundSize: 'cover'
  //       }}
  //     >
  //       <h3 className="advert_preview_title"
  //         style={{
  //           color: advert.title_color,
  //         }}
  //       >
  //         {advert.title}
  //       </h3>
  //       <button
  //         href={advert.cta_link}
  //         className="advert_preview_cta"
  //         style={{
  //           color: advert.cta_text_color,
  //           background: advert.cta_background_color
  //         }}
  //       >
  //         { advert.cta_text }
  //       </button>
  //       <img className="advert_preview_logo" src={logo}/>
  //     </div>
  //   )
  // }

  render() {
    let {
      addMode,
      insurance,
      loading
    } = this.state

    let title = addMode ? "Save" : "Update"

    return (
      <div className="row">
				<div className="col-md-6">
					<Div className="kt-portlet" disabled={loading}>
						<div className="kt-portlet__head">
							<div className="kt-portlet__head-label">
								<h3 className="kt-portlet__head-title">
                  Insurance
								</h3>
							</div>
						</div>


						<form className="kt-form">
							<div className="kt-portlet__body">
								<h5 className="kt-section__title">Details:</h5><br/>

                <label>Logo Image</label>
                <ImageEdit
                  validWidth={111}
                  image={insurance.logo_url}
                  onUpdated={image => {
                    let updatedInsurance = {...insurance};
                    updatedInsurance.logo_url = image;
                    updatedInsurance.logo = image.id;
                    console.log(updatedInsurance);
                    this.setState({ insurance: updatedInsurance })
                  }}
                />

                <div className="form-group">
									<label>Insurer Name</label>
									<input type="text" className="form-control"
                    value={insurance.insurer_name}
                    onChange={e => {
                      insurance.insurer_name = e.target.value
                      this.setState({ insurance })
                    }}
                    placeholder="Name"
                  />
								</div>

								<div className="form-group">
									<label>Title</label>
									<input type="text" className="form-control"
                    value={insurance.cta_title}
                    onChange={e => {
                      insurance.cta_title = e.target.value
                      this.setState({ insurance })
                    }}
                    placeholder="Name"
                  />
								</div>

                <div className="form-group">
									<label>Subtitle</label>
									<input type="text" className="form-control"
                    value={insurance.cta_subtitle}
                    onChange={e => {
                      insurance.cta_subtitle = e.target.value
                      this.setState({ insurance })
                    }}
                    placeholder="Name"
                  />
								</div>

                <div className="form-group">
									<label>Link</label>
									<input type="text" className="form-control"
                    value={insurance.cta_link}
                    onChange={e => {
                      insurance.cta_link = e.target.value
                      this.setState({ insurance })
                    }}
                    placeholder="Name"
                  />
								</div>

                {/* <ColorPicker
                  title="CTA Text Color"
                  value={insurance.cta_text_color}
                  onChange={color => {
                    console.log("ccc", color)
                    insurance.cta_text_color = color
                    this.setState({ insurance })
                  }}
                />

                <ColorPicker
                  title="CTA Background Color"
                  value={insurance.cta_background_color}
                  onChange={color => {
                    console.log("ccc", color)
                    insurance.cta_background_color = color
                    this.setState({ insurance })
                  }}
                />

                <ColorPicker
                  title="Background Color"
                  value={insurance.cta_background_color}
                  onChange={color => {
                    console.log("ccc", color)
                    insurance.cta_background_color = color
                    this.setState({ insurance })
                  }}
                /> */}
							</div>

							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<button type="reset" onClick={() => this._handleSave()} className="btn btn-primary">
                    { title }
                  </button>
								</div>
							</div>
						</form>
					</Div>
				</div>
        
        {/* <div className="col-md-6">
					<div className="kt-portlet">
						<div className="kt-portlet__head">
							<div className="kt-portlet__head-label">
								<h3 className="kt-portlet__head-title">
                  Previews
								</h3>
							</div>
						</div>
            <div className="kt-portlet__body">

              { this._renderPreviewCardStack(advert, cover, logo) }
              { this._renderPreviewBanner(advert, cover, logo) }

            </div>
          </div>
        </div> */}

			</div>
    )
  }
}
