import React from 'react'

export default class ScreenActionsCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      screen: props.screen
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderManage(screen) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-primary btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onManageSectionsClick(screen)
        }}
      >
        Sections
      </a>
    )
  }

  _renderEdit(screen) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-primary btn-bold"
        style={{ marginLeft: '10px'}}
        onClick={e => {
          e.preventDefault()
          this.props.onEditClick(screen)
        }}
      >
        Edit
      </a>
    )
  }

  _renderDelete(screen) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-danger btn-bold"
        style={{ marginLeft: '10px'}}
        onClick={e => {
          e.preventDefault()
          this.props.onDeleteClick(screen)
        }}
      >
        Delete
      </a>
    )
  }

  render() {
    let { screen } = this.state;
    
    return (
      <span style={{ overflow: 'visible', position: 'relative', width: '110px' }}>
        {this._renderManage(screen)}
        {this._renderEdit(screen)}
        {this._renderDelete(screen)}
      </span>
    )
  }
}
