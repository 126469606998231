import React from 'react'

import BaseTable from './BaseTable'
import DraggableTable from './DraggableTable/DraggableTable'

import CollectionActionsCell from './cells/CollectionActionsCell'
import DateDisplayCell from './cells/DateDisplayCell'

export default class CollectionsTable extends React.Component {
  _getColumns() {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Type',
        accessor: 'collection_type',

      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 200
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 90
      },
      {
        Header: 'Stocks',
        accessor: 'no_of_vehicles',
        width: 70
      },
      {
        Header: 'Updated at',
        accessor: 'updated_at',
        Cell: rowInfo => {
          return <DateDisplayCell dateValue={rowInfo['value']} />
        }
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: rowInfo => {
          return <DateDisplayCell dateValue={rowInfo['value']} />
        }
      },
      {
        id: 'active',
        Header: 'Actions',
        width: 230,
        Cell: rowInfo => {
          if (this.props.collection_set) {
            return <CollectionActionsCell
              in_set={true}
              collection={rowInfo}
              onRemoveClick={collection => this.props.onRemoveCollectionClick(collection)}
            />
          }
          return <CollectionActionsCell
            collection={rowInfo.original}
            onManageFiltersClick={collection => this.props.onManageFiltersClick(collection)}
            onEditClick={collection => this.props.onEditCollectionClick(collection)}
            onDeleteClick={collection => this.props.onDeleteCollectionClick(collection)}
          />  
        }
      }
    ]
  }

  render() {
    const { collection_set } = this.props;
    let collections_in_set;
    if (collection_set) {
      collections_in_set = collection_set.collections.map(({ collection }, index) => {
        let c = Object.assign({}, collection);
        c.order = collection_set.collections[index].order;
        return c;
      });
    }
    
    const columns = this._getColumns()
    return (
      <div>
        {collection_set ? 
          <DraggableTable
            data={collections_in_set}
            columns={columns}
            onUpdateOrder={this.props.onUpdateOrderInSet}
            onDataReceived={data => {
              console.log(data);
            }}
          /> :
          <BaseTable
            endpoint={window.Api.Collections}
            columns={columns}
            onDataReceived={data => {
              this.setState({ data })
            }}
          />
        }
      </div>
    )
  }
}
