import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from '../../../utils/Backend'
import MySelect from '../common/MySelect'

export default class AddComponentToSectionModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      selected_type: "",
      content_types: [],
      components: [],
      selected_component: "",
      section: null,
      loading: false,
    }
  }

  componentDidMount() {
    Backend.getComponentTypes().then(data => {
      this.setState({content_types: data});
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _submit(){
    let {
      selected_type,
      selected_component
    } = this.state


    if (selected_type === "") {
      alert("Please select a Component Type")
      return
    }
    if (selected_component === "") {
      alert("Please select a Component")
      return
    }
    
    
    this.setState({ loading: true })
    let { section } = this.state;
    
    this.props.processComponents(section.components, () => {
      section.components.push({
        content_type: parseInt(selected_type),
        component_id: parseInt(selected_component),
        order: 1
      });
      Backend.editSection(section)
      .then(() => {
        this.setState({ loading: false })
        this.props.onSubmit()
      }).catch(error => {
        console.log(error)
        this.setState({ loading: false })
        // this.props.onSubmit();
      });
    });
  }

  _onSelectComponentType = (e) => {
    const type = e.target.value;
    const typeObj = this.state.content_types.find(ct => ct.value + '' === type + '');

    if (typeObj) {
      let method = typeObj.label === 'Collection' ? Backend.getCollectionsNames : typeObj.label === 'Collection Set' ? Backend.getCollectionSetsNames : Backend.getRecommendationsNames;

      this.setState({
        selected_type: type,
        loading: true
      });

      method().then(data => {
        const components = data.map(set => {
          return {
            label: set.name,
            value: set.id
          }
        });
        this.setState({
          selected_component: "",
          components,
          loading: false
        });
      }).catch(error => {
        alert(error.message);
        this.setState({
          selected_component: "",
          loading: false
        });
      });
    } else {
      this.setState({
        selected_type: type,
        selected_component: ""
      });
    }
  }

  _getComponents() {
    const { components } = this.state;
    if (components.length > 0) {
      return components.map((component) => {
        return {
          label: component.name, value: component.id
        }
      });
    }
  }

  render() {
    let {
      show,
      loading,
      selected_type,
      content_types,
      selected_component,
      components
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Component</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group mt-3">
            <label>Component Type</label><br/>
            <MySelect
              value={selected_type}
              options={content_types}
              onChangeSelect={this._onSelectComponentType}
            />
          </div>

          <div className="form-group mt-3">
            <label>Component</label><br />
            <MySelect
              value={selected_component}
              options={components}
              disabled={loading}
              onChangeSelect={(e) => {
                this.setState({ selected_component: e.target.value })
              }}
            />
          </div>
          
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            disabled={loading}
            onClick={() => this.props.onHide()}>Cancel</button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading || !this.state.selected_component || !this.state.selected_type}
            onClick={() => this._submit()}>Submit</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
