import React from 'react'

import BaseTable from './BaseTable'

import CollectionSetActionsCell from './cells/CollectionSetActionsCell'
import DateDisplayCell from './cells/DateDisplayCell'


export default class CollectionSetsTable extends React.Component {
  _getColumns() {
    return [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 150,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 70
      },
      // {
      //   Header: 'Type',
      //   accessor: 'type'
      // },
      {
        Header: 'Updated at',
        accessor: 'updated_at',
        width: 180,
        Cell: rowInfo => {
          return <DateDisplayCell dateValue={rowInfo['value']} />
        }
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        width: 180,
        Cell: rowInfo => {
          return <DateDisplayCell dateValue={rowInfo['value']} />
        }
      },
      {
        Header: "Actions",
        width: 250,
        Cell: rowInfo => {
          return (
            <CollectionSetActionsCell
              collection_set={rowInfo.original}
              onManageCollectionsClick={collection_set => this.props.onManageCollectionsClick(collection_set)}
              onEditClick={collection_set => this.props.onEditCollectionSetClick(collection_set)}
              onDeleteClick={collection_set => this.props.onDeleteCollectionSetClick(collection_set)}
            />
          )
        }
      }
    ]
  }

  render() {
    const columns = this._getColumns()
    return (
      <div>
        <BaseTable
          endpoint={window.Api.CollectionSets}
          columns={columns}
        />
      </div>
    )
  }
}
