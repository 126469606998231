import React from "react"

import BaseTable from "./BaseTable"
import BodyActionsCell from "./cells/BodyActionsCell"


export default class BodiesTable extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      endpoint: window.Api.Bodies
    }
  }

  _getColumns() {
    return [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        id: "image_id",
        Header: 'Actions',
        Cell: rowInfo => {
          return (
            <BodyActionsCell body={rowInfo.original}/>
          )
        }
      }
    ]
  }

  render() {
    let {
      endpoint
    } = this.state

    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          endpoint={endpoint}
          columns={columns}
        />
      </div>
    )
  }
}

BodiesTable.defaultProps = {
}
