import React from "react"

import StatCard from './components/cards/StatCard'
import Card from './components/cards/Card'
import DealersTable from './components/tables/DealersTable'

import Backend from '../utils/Backend'

export default class Dealers extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      searchTerm: '',
      statusFilters: [],
    }
  }

  componentDidMount(){
    this._loadStats()
  }

  _loadStats(){
    Backend.getDealersStats()
    .then(stats => {
      this.setState({
        stats,
        loading: false
      })
    })

  }

  render() {
    let {
      stats,
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <div>
        <div className="row">
  
            { stats && stats.active_no && <StatCard
                title="Active Dealers"
                value={ stats.active_no || 0 }
                size="col-md-3 col-lg-3 col-xl-3"
              /> }
              { stats && stats.signed_up_no &&<StatCard
                title="Signed Up Dealers"
                value={ stats.signed_up_no }
                size="col-md-3 col-lg-3 col-xl-3"
              /> }
               { stats && stats.new_7_days_no && <StatCard
                title="New in Last 7 Days"
                value={ stats.new_7_days_no }
                size="col-md-3 col-lg-3 col-xl-3"
              /> }
               { stats && stats.new_30_days_no &&  <StatCard
                title="New in Last 30 Days"
                value={ stats.new_30_days_no }
                size="col-md-3 col-lg-3 col-xl-3"
              /> }
        
  			</div>
        <div className="row">
          <Card
            title="Dealers"
            className="col-xl-12"
          >
            <DealersTable
              onDeactivatedDealer={() => {
                stats.active_no -= 1
                this.setState({ stats })
              }}
              onActivatedDealer={() => {
                stats.active_no += 1
                this.setState({ stats })
              }}
              onEditDealerClick={dealer => {
                console.log("here")
                //this.props.history.push(`/dealers/${dealer.id}`)
                window.open(`/dealers/${dealer.id}`, "_blank")
              }}
            />
          </Card>
        </div>
      </div>
    )
  }
}
