import React from 'react'

export default class InsuranceActionsCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      insurance: props.insurance
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderEdit(insurance){
    return (
      <a href="#"
        className="btn btn-sm btn-label-warning btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onEditClick(insurance)
        }}
      >
        Edit
      </a>
    )
  }

  _renderToggleActivate(insurance){
    let label = insurance.active ? "danger" : "info"
    let title = insurance.active ? "Unpublish" : "Publish"

    return (
      <a href="#"
        className={`btn btn-sm btn-label-${label} btn-bold`}
        style={{ marginLeft: 10 }}
        onClick={e => {
          e.preventDefault()

          if(insurance.active){
            this.props.onDeactivateClick({ ...insurance })
          }
          else{
            this.props.onActivateClick({ ...insurance })
          }

          insurance.active = !insurance.active

          this.setState({ insurance })
        }}
      >
        { title }
      </a>
    )
  }


  render() {
    let { insurance } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative', width: '110px'}}>
        { this._renderEdit(insurance) }
        { this._renderToggleActivate(insurance) }
      </span>
    )
  }
}
