import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/requestResetPassword"
window.Api.User.ResetPassword         = window.Api.User.Base + "/resetPassword"
window.Api.User.RefreshToken          = window.Api.User.Base + "/login/refresh"


window.Api.Dealers    = window.Api.Base + "/dealers"
window.Api.Vehicles   = window.Api.Base + "/vehicles"
window.Api.Stats      = window.Api.Base + "/stats"
window.Api.Refunds    = window.Api.Base + "/refunds"
window.Api.Customers  = window.Api.Base + "/customers"
window.Api.Staff      = window.Api.Base + "/staff"
window.Api.Adverts    = window.Api.Base + "/adverts"
window.Api.Insurance    = window.Api.Base + "/insurance"
window.Api.Images     = "https://sweep-api.eu-west-1.elasticbeanstalk.com" + "/images"
window.Api.Bodies     = window.Api.Base + "/bodies"

window.Api.CollectionSets = window.Api.Base + "/cms/collectionsets"
window.Api.Collections = window.Api.Base + "/cms/collections"
window.Api.Filters = window.Api.Base + "/cms/filters"
window.Api.Recommendations = window.Api.Base + "/cms/recommendations"

window.Api.Screens = window.Api.Base + "/cms/screens"
window.Api.Sections = window.Api.Base + "/cms/sections"
window.Api.ComponentTypes = window.Api.Sections + "/types"

window.Api.SkupeNetDealers = window.Api.Base + "/skupenet-dealers"

window.Api.MDMUnmappedItems     = window.Api.Base + "/mdm/unmapped"
window.Api.MDMParentItems     = window.Api.Base + "/mdm/parent"
window.Api.MDMChildItems     = window.Api.Base + "/mdm/children"

window.Api.MarketingMessage = window.Api.Base + "/marketing-message"