import React from 'react'

import BaseTable from './BaseTable'
import DraggableTable from './DraggableTable/DraggableTable'

import MarketinMessageActionsCell from './cells/MarketingMessageActionsCell'
import DateDisplayCell from './cells/DateDisplayCell'

export default class MarketingMessageTable extends React.Component {
  _getColumns() {
    return [
      {
        Header: 'Title',
        accessor: 'message_title'
      },
      {
        Header: 'Message Content',
        accessor: 'message_content',
        width: 200
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: 70
      },
      {
        Header: 'Segment',
        accessor: 'segment',
        width: 90
      },
      {
        Header: 'Send Day',
        accessor: 'send_day',
        width: 70
      },
      {
        Header: 'Send Date',
        accessor: 'send_date',
        width: 70
      },
      {
        Header: 'Send Time',
        accessor: 'send_time',
        width: 70
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        width: 70
      },
      {
        Header: 'Status',
        accessor: 'active',
        width: 70,
        Cell: rowInfo => {
          console.log("Rowinfo,", rowInfo)
          return rowInfo.original.active ? 'Active' : 'Inactive'
        }
      },
      {
        Header: "Actions",
        width: 150,
        Cell: rowInfo => {
          return (
            <MarketinMessageActionsCell
              marketing_message={rowInfo.original}
              onEditClick={marketing_message => this.props.onEditMarketingMessageClick(rowInfo.original)}
              onDeleteClick={marketing_message => this.props.onDeleteMarketingMessageClick(rowInfo.original)}
            />
          )
        }
      }
    ]
  }

  render() {


    const columns = this._getColumns()
    return (
      <div>
        <BaseTable
          endpoint={window.Api.MarketingMessage}
          columns={columns}
          onDataReceived={data => {
            this.setState({ data })
          }}
        />
      </div>
    )
  }
}
