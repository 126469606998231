import React from "react"

import StatCard from './components/cards/StatCard'
import Card from './components/cards/Card'
import CustomersTable from './components/tables/CustomersTable'

import Backend from '../utils/Backend'

export default class Customers extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      searchTerm: '',
      statusFilters: [],
    }
  }

  componentDidMount(){
    this._loadStats()
  }

  _loadStats(){
    Backend.getCustomersStats()
    .then(stats => {
      this.setState({
        stats,
        loading: false
      })
    })

  }

  render() {
    let {
      stats,
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <div>
        <div className="row">
  				<StatCard
            title="Total Users"
            value={ stats.total_no }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          <StatCard
            title="New in Last 24 Hours"
            value={ stats.new_1_day_no }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          <StatCard
            title="New in Last 7 Days"
            value={ stats.new_7_days_no }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          <StatCard
            title="New in Last 30 Days"
            value={ stats.new_30_days_no }
            size="col-md-3 col-lg-3 col-xl-3"
          />
  			</div>
        <div className="row">
          <Card
            title="Users"
            className="col-xl-12"
          >
            <CustomersTable
              onDeactivatedCustomer={() => {
                stats.active_no -= 1
                this.setState({ stats })
              }}
              onActivatedCustomer={() => {
                stats.active_no += 1
                this.setState({ stats })
              }}
            />
          </Card>
        </div>
      </div>
    )
  }
}
