import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from '../../utils/Backend'


const modelFields = {
  'body': [{name: 'type', label: 'Type'}],
  'fuel': [{name: 'type', label: 'Type'}],
  'transmission': [{name: 'type', label: 'Type'}],
  'manufacturer': [{name: 'name', label: 'Name'}],
  'model': [{name: 'name', label: 'Name'}],
  'vehicle': [{name: 'color', label: 'Color'}],
  'location': [{name: 'state', label: 'State'}],
}

export default class NewParentItemModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      show: props.show,
      parentItem: {}
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  onFieldChange = (field, value) => {
    const updated = { ...this.state.parentItem };
    updated[field] = value;
    this.setState({ parentItem: updated });
  }

  onEntityChange = (value) => {
    const updated = { ...this.state.parentItem };
    updated['entity'] = value;
    updated['target_field'] = '';
    updated['value'] = '';
    this.setState({ parentItem: updated });
  }

  _submit = () => {
    Backend.createParentMappedItem(this.state.parentItem)
      .then(res => {
        this.props.onHide(true)
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  _renderError = () => {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div className="kt-alert kt-alert--outline alert alert-danger alert-dismissible" role="alert">
        <span>{error}</span>
      </div>
    )
  }

  render() {
    let { show } = this.state;

    let isEnabled = this.state.parentItem && this.state.parentItem.entity && this.state.parentItem.value && this.state.parentItem.target_field;

    if (this.state.parentItem && this.state.parentItem.entity === 'model') {
      isEnabled = !this.state.parentItem.parent ? false : isEnabled;
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Parent Item</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this._renderError()}

          <div className="form-group">
            <label>Select Entity</label>
            <select
              className="form-control m-t-0 b-space"
              value={this.state.parentItem.entity}
              onChange={e => {
                this.onEntityChange(e.target.value);
              }}
            >
              <option value="" disabled selected></option>
              <option value="body">Body</option>
              <option value="fuel">Fuel</option>
              <option value="location">Location</option>
              <option value="manufacturer">Manufacturer (Make)</option>
              <option value="model">Model</option>
              <option value="transmission">Transmission</option>
              <option value="vehicle">Vehicle</option>
            </select>
          </div>

          <div className="form-group">
            <label>Select Field</label>
            <select
              className="form-control m-t-0 b-space"
              value={this.state.parentItem.target_field}
              onChange={e => this.onFieldChange('target_field', e.target.value)}
              disabled={!this.state.parentItem.entity}
            >
              <option value="" disabled selected></option>
              {(modelFields[this.state.parentItem.entity] || []).map((f, idx) => {
                return <option value={f['name']} key={idx}>{f['label']}</option>
              })}
            </select>
          </div>

          <div className="form-group">
            <label>Value</label>
            <input type="text" className="form-control"
              value={this.state.parentItem.value}
              onChange={e => this.onFieldChange('value', e.target.value)}
              placeholder="Value"
            />
          </div>

          {this.state.parentItem && this.state.parentItem.entity === 'model' ? <div className="form-group">
            <label>Manufacturer</label>
            <input type="text" className="form-control"
              value={this.state.parentItem.parent}
              onChange={e => this.onFieldChange('parent', e.target.value)}
              placeholder="Manufacturer"
            />
          </div> : null}

        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}>Cancel</button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={!isEnabled}
            onClick={() => this._submit()}>Submit</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
