import React from "react"

import Card from './components/cards/Card'
import SectionsTable from './components/tables/SectionsTable'
import Backend from '../utils/Backend'

export default class Sections extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      searchTerm: '',
      statusFilters: [],
    }
  }

  componentDidMount(){
    this.setState({
      loading: false
    })
  }

  render() {
    let {
      loading
    } = this.state

    if(loading) {
      return null
    }

    return (
      <div>
        <div className="row">
          <Card
            title="Sections"
            className="col-xl-12"
            renderToolbar={() => {
              return (
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    &nbsp;
                    <div className="dropdown dropdown-inline">
                      <button
                        type="button"
                        className="btn btn-brand btn-icon-sm"
                        onClick={() => this.props.history.push("/cms/create-section")}
                      >
                        <i className="flaticon2-plus"></i> New Section
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          >
          <SectionsTable
            onManageComponentsClick={section => {
              this.props.history.push(`/cms/components/${section.id}`)
            }}
            onEditSectionClick={section => {
              this.props.history.push(`/cms/section/${section.id}`)
            }}
            onDeleteSectionClick={section => {
              Backend.deleteSection(section.id)
              .then(() => {document.location.reload();})
              .catch(error => alert(error.message));
            }}
          /> 
          </Card>
        </div>
      </div>
    )
  }
}
