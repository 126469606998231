import React from "react"

import CollectionSetDetail from './components/CollectionSetDetail'

import Backend from '../utils/Backend'

export default class EditCollectionSet extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      collection_set: null,
      loading: true,
    }
  }

  componentDidMount(){
    this._loadCollectionSet()
  }

  _loadCollectionSet(){
    let id = this.props.match.params.collection_set_id
    if(!id){
      this.setState({ loading: false })
      return
    }

    Backend.getCollectionSet(id)
    .then(collection_set => {
      this.setState({
        collection_set,
        loading: false
      })
    })
  }

  _goToCollectionSets(){
    this.props.history.push(`/collection/collection-sets`)
  }

  render() {
    let {
      collection_set,
      loading
    } = this.state

    if (loading) {
      return null
    }

    return (
      <CollectionSetDetail
        collection_set={collection_set}
        goToCollectionSets={() => this._goToCollectionSets()}
      />
    )
  }
}
