import React from "react"

import Div from './layouts/Div'
import MySelect from './common/MySelect'
import ImageEdit from './common/ImageEdit'

import Backend from '../../utils/Backend'

export default class CollectionSetDetail extends React.Component {
  constructor(props){
    super(props)

    let addMode = props.collection_set == null

    this.state = {
      addMode,
      collection_set: props.collection_set || { name: "", description: "", type: "", status: "" }
    }
  }

  _isValid(data){
    let error = null;
    const {
      name,
      description,
      type,
      status
    } = data;

    if (name === "") error = "Enter name please!";
    if (description === "") error = "Enter description please!";
    // if (type === "") error = "Select type please!";
    if (status === "") error = "Select status please!";

    if(error) {
      alert(error);
      return false;
    }

    return true
  }

  _handleSave(){
    let {
      collection_set
    } = this.state

    if(!this._isValid(collection_set)){
      return
    }

    this._save()
  }

  _save(){
    let {
      collection_set,
      addMode
    } = this.state

    this.setState({loading: true})

    const modifiedCollectionSet = collection_set.collections ? { ...collection_set, collections: collection_set.collections.map((c) => ({ ...c, collection: c.collection.id })) } : { ...collection_set };

    this._updateBackend(addMode, modifiedCollectionSet)
    .then(collection_set => {
      this.setState({
        collection_set,
        loading: false,
        addMode: false
      })
      this.props.goToCollectionSets()
    })
    .catch(error => {
      console.log(error);
      this.setState({loading: false})
    })
  }

  _updateBackend(addMode, collection_set){
    if(addMode) return Backend.createCollectionSet(collection_set)
    else return Backend.editCollectionSet(collection_set)
  }

  _getCollectionSetTypes() {
    return [
      { value: "consumer_recommendation", label: "Consumer Recommendation" },
      { value: "dealer_recommendation", label: "Dealer Recommendation" },
      { value: "vehicle_collection", label: "Vehicle Collection" }
    ]
  }

  _getCollectionSetStatuses() {
    return [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" }
    ]
  }

  _onUpdateBackgroundImage(image) { 
    let collection_set = this.state.collection_set;
    collection_set.background_image = image;
    this.setState({
      collection_set
    });
  }

  render() {
    let {
      addMode,
      collection_set,
      loading
    } = this.state

    let title = addMode ? "Save" : "Update"


    return (
      <div className="row">
				<div className="col-md-6">
					<Div className="kt-portlet" disabled={loading}>
						<div className="kt-portlet__head">
							<div className="kt-portlet__head-label">
								<h3 className="kt-portlet__head-title">
                  Collection Set
								</h3>
							</div>
						</div>


						<form className="kt-form">
							<div className="kt-portlet__body">
								<h5 className="kt-section__title">Details:</h5><br/>

								<div className="form-group">
									<label>Name</label>
									<input type="text" className="form-control"
                    value={collection_set.name}
                    onChange={e => {
                      collection_set.name = e.target.value
                      this.setState({ collection_set })
                    }}
                    placeholder="Name"
                  />
								</div>

                <div className="form-group">
                  <label>Description</label>
                  <textarea value={collection_set.description}
                    onChange={e => {
                      collection_set.description = e.target.value
                      this.setState({ collection_set })
                    }}
                    placeholder="Description" className="form-control" />
                </div>
                
                <div className="form-group">
                  <label>Background Image</label>
                  <ImageEdit image={collection_set.background_image} onUpdated={this._onUpdateBackgroundImage.bind(this)} />
                </div>

                <div className="form-group">
                  <label>Status</label>
                  <MySelect
                    value={collection_set.status}
                    options={this._getCollectionSetStatuses()}
                    onChangeSelect={(e) => {
                      collection_set.status = e.target.value;
                      this.setState({
                        collection_set
                      });
                    }}
                  />
                </div>

                <div className="form-group">
                  <label>Type</label>
                  <MySelect
                    value={collection_set.type}
                    options={this._getCollectionSetTypes()}
                    onChangeSelect={(e) => {
                      collection_set.type = e.target.value;
                      this.setState({
                        collection_set
                      });
                    }}
                  />
                </div>
							</div>

							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<button type="reset" onClick={() => this._handleSave()} className="btn btn-primary">
                    { title }
                  </button>
								</div>
							</div>
						</form>
					</Div>
				</div>
			</div>
    )
  }
}
