import React from 'react'

export default class ComponentActionsCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      component: props.component
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderRemove(component) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-danger btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onRemoveClick(component)
        }}
      >
        Remove
      </a>
    )
  }

  render() {
    let { component } = this.state;
    if (this.props.in_section) {
      return (
        <span style={{ overflow: 'visible', position: 'relative', width: '110px' }}>
          {this._renderRemove(component)}
        </span>
      );
    }
  }
}
