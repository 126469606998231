import FetchHelper from './FetchHelper'

export default class Backend {

 
  static getAdvert(id){
    return FetchHelper.get(`${window.Api.Adverts}/${id}`)
  }

  static activateAdvert(advert){
    advert.active = true
    return Backend.editAdvert(advert)
  }

  static deactivateAdvert(advert){
    advert.active = false
    return Backend.editAdvert(advert)
  }

  static editAdvert(advert){
    let data = { ...advert }
    if(advert.background_image){
      data.background_image = advert.background_image.id
    }
    if(advert.logo){
      data.logo = advert.logo.id
    }
    if(advert.large_advert_image){
      data.large_advert_image = advert.large_advert_image.id
    }
    if(advert.small_advert_image){
      data.small_advert_image = advert.small_advert_image.id
    }
    return FetchHelper.patch(`${window.Api.Adverts}/${advert.id}`, data)
  }

  static createAdvert(advert){
    let data = { ...advert }
    if(advert.background_image){
      data.background_image = advert.background_image.id
    }
    if(advert.logo){
      data.logo = advert.logo.id
    }
    if(advert.large_advert_image){
      data.large_advert_image = advert.large_advert_image.id
    }
    if(advert.small_advert_image){
      data.small_advert_image = advert.small_advert_image.id
    }
    return FetchHelper.post(`${window.Api.Adverts}`, data)
  }

  
  static getInsurance(id){
    return FetchHelper.get(`${window.Api.Insurance}/${id}`)
  }

  static activateInsurance(insurance){
    insurance.active = true
    return Backend.editInsurance(insurance)
  }

  static deactivateInsurance(insurance){
    insurance.active = false
    return Backend.editInsurance(insurance)
  }

  static editInsurance(insurance){
    let data = { ...insurance }
    if (insurance.logo_url) {
      data.logo_url = insurance.logo_url.id
    }
    return FetchHelper.patch(`${window.Api.Insurance}/${insurance.id}`, data)
  }

  static createInsurance(insurance){
    let data = { ...insurance }
    if(insurance.logo_url){
      data.logo_url = insurance.logo_url.id
    }
    return FetchHelper.post(`${window.Api.Insurance}`, data)
  }


  static getDealer(id){
    return FetchHelper.get(`${window.Api.Dealers}/${id}`)
  }

  static activateDealer(dealer){
    dealer.active = true
    return Backend.editDealer(dealer)
  }

  static deactivateDealer(dealer){
    dealer.active = false
    return Backend.editDealer(dealer)
  }

  static featureDealer(dealer){
    dealer.featured = true
    return Backend.editDealer(dealer)
  }

  static unfeatureDealer(dealer){
    dealer.featured = false
    return Backend.editDealer(dealer)
  }

  static editDealer(dealer){
    let data = { ...dealer }
    if (dealer.dealer_profile_image) {
      data.dealer_profile_image = dealer.dealer_profile_image.id
    }
    return FetchHelper.patch(`${window.Api.Dealers}/${data.id}`, data)
  }

  static editStaff(staff){
    return FetchHelper.patch(`${window.Api.Staff}/${staff.user.id}`, staff)
  }

  static createStaff(dealer, staff){
    let data = staff
    data.dealer = dealer.id
    return FetchHelper.post(`${window.Api.Staff}`, data)
  }

  static activateVehicle(vehicle){
    return Backend.editVehicle({ id: vehicle.id, active: true })
  }

  static deactivateVehicle(vehicle){
    return Backend.editVehicle({ id: vehicle.id, active: false })
  }

  static editVehicle(vehicle){
    return FetchHelper.patch(`${window.Api.Vehicles}/${vehicle.id}`, vehicle)
  }

  static activateCustomer(customer){
    return Backend.editCustomer({ id: customer.user.id, user: { status: "active" } })
  }

  static deactivateCustomer(customer){
    return Backend.editCustomer({ id: customer.user.id, user: { status: "banned" } })
  }

  static limitCustomer(customer){
    return Backend.editCustomer({ id: customer.user.id, user: { status: "limited-account" } })
  }

  static editCustomer(customer){
    return FetchHelper.patch(`${window.Api.Customers}/${customer.id}`, customer)
  }

  static getLatestDealers(){
    return FetchHelper.get(`${window.Api.Dealers}?order_by=-created_at&page_size=5`)
    .then(response => {
      return response.results
    })
  }

  static getLatestVehicles(){
    return FetchHelper.get(`${window.Api.Vehicles}?order_by=-created_at&page_size=5`)
    .then(response => {
      return response.results
    })
  }

  static getAllStats(){
    return Backend.getStats({
      dealers: true,
      customers: true,
      vehicles: true,
      adverts: true,
    })
  }

  static getDealersStats(){
    return Backend.getStats({dealers: true})
    .then(stats => {
      return stats.dealers
    })
  }

  static getCustomersStats(){
    return Backend.getStats({customers: true})
    .then(stats => {
      return stats.customers
    })
  }

  static getVehiclesStats(){
    return Backend.getStats({vehicles: true})
    .then(stats => {
      return stats.vehicles
    })
  }

  static getAdvertStats(){
    return Backend.getStats({adverts: true})
    .then(stats => {
      return stats.adverts
    })
  }

  static getStats(data){

    let dealers   = data.dealers   || false
    let customers = data.customers || false
    let vehicles  = data.vehicles  || false
    let adverts   = data.adverts   || false

    let url = `${window.Api.Stats}?dealers=${dealers}&customers=${customers}&vehicles=${vehicles}&adverts=${adverts}`
    return FetchHelper.get(url)
  }

  static refundPayment(vehicle){
    let data = {
      payment_id: vehicle.id
    }
    return FetchHelper.post(`${window.Api.Refunds}`, data)
  }

  static uploadImage(image){
    let data = new FormData()
    data.append("file", image, image.name)
    return FetchHelper.post(window.Api.Images, data, true)
  }

  static addSkupeNetDealer(name, key){
    let data = {
      name,
      key
    }
    return FetchHelper.post(window.Api.SkupeNetDealers, data)
  }

  static updateBody(body){
    let data = {
      image: body.image ? body.image.id : null
    }
    return FetchHelper.patch(`${window.Api.Bodies}/${body.id}`, data)
  }

  static addAsParentOrChild(items, type, parentItem) {
    let data = { items, add_as: type, parent_item: parentItem }
    return FetchHelper.post(`${window.Api.MDMUnmappedItems}/move/`, data)
  }


  static parseImageId(data) {
    if (data.background_image) {
      data.background_image = data.background_image.id
    }
    return data;
  }


  static getAllRecommendations() {
    return FetchHelper.get(`${window.Api.Recommendations}`)
  }

  static getRecommendationsNames() {
    return FetchHelper.get(`${window.Api.Recommendations}/names`)
  }

  static getAllCollectionSets() {
    return FetchHelper.get(`${window.Api.CollectionSets}`)
  }

  static getCollectionSetsNames() {
    return FetchHelper.get(`${window.Api.CollectionSets}/names`)
  }

  static getCollectionSet(id) {
    return FetchHelper.get(`${window.Api.CollectionSets}/${id}`)
  }

  static editCollectionSet(collectionSet) {
    return FetchHelper.patch(`${window.Api.CollectionSets}/${collectionSet.id}`, this.parseImageId(collectionSet))
  }

  static createCollectionSet(collectionSet){
    collectionSet.order = 1;
    return FetchHelper.post(`${window.Api.CollectionSets}`, this.parseImageId(collectionSet))
  }

  static deleteCollectionSet(id) {
    return FetchHelper.delete(`${window.Api.CollectionSets}/${id}`)
  }


  static getAllCollections() {
    return FetchHelper.get(`${window.Api.Collections}`)
  }

  static getCollectionsNames() {
    return FetchHelper.get(`${window.Api.Collections}/names`)
  }
  
  static getCollection(id) {
    return FetchHelper.get(`${window.Api.Collections}/${id}`)
  }

  static getCollectionsByCollectionSet(id) {
    return FetchHelper.get(`${window.Api.Collections}/collection_set/${id}`)
  }

  static editCollection(collection) {
    return FetchHelper.patch(`${window.Api.Collections}/${collection.id}`, this.parseImageId(collection))
  }

  static createCollection(collection) {
    collection.order = 1;
    return FetchHelper.post(`${window.Api.Collections}`, this.parseImageId(collection))
  }

  static deleteCollection(id) {
    return FetchHelper.delete(`${window.Api.Collections}/${id}`)
  }

  static getAllMarketingMessages() {
    return FetchHelper.get(`${window.Api.Sections}`)
  }
  
  static getMarketingMessage(id) {
    return FetchHelper.get(`${window.Api.MarketingMessage}/${id}/`)
  }

  static editMarketingMessage(marketingMessage) {
    return FetchHelper.patch(`${window.Api.MarketingMessage}/${marketingMessage.id}/`, marketingMessage);
  }

  static createMarketingMessage(marketingMessage) {
    return FetchHelper.post(`${window.Api.MarketingMessage}`, marketingMessage)
  }

  static deleteMarketingMessage(id) {
    return FetchHelper.delete(`${window.Api.MarketingMessage}/${id}/`)
  }
  
  

  static createFilter(filter) {
    return FetchHelper.post(`${window.Api.Filters}`, filter)
  }
  static addFilterToCollection(filter_type, key, value, collection_id) {
    const data = { filter_type, key, value };
    return FetchHelper.post(`${window.Api.Filters}/${collection_id}`, data);
  }
  
  static removeFilterFromCollection(filter_id, collection_id) {
    return FetchHelper.delete(`${window.Api.Filters}/${filter_id}`);
  }


  static getScreen(id) {
    return FetchHelper.get(`${window.Api.Screens}/${id}`)
  }

  static editScreen(screen) {
    return FetchHelper.patch(`${window.Api.Screens}/${screen.id}`, screen);
  }

  static createScreen(screen) {
    return FetchHelper.post(`${window.Api.Screens}`, screen)
  }

  static deleteScreen(id) {
    return FetchHelper.delete(`${window.Api.Screens}/${id}`)
  }


  static getAllSections() {
    return FetchHelper.get(`${window.Api.Sections}`)
  }
  static getSection(id) {
    return FetchHelper.get(`${window.Api.Sections}/${id}`)
  }

  static editSection(section) {
    return FetchHelper.patch(`${window.Api.Sections}/${section.id}`, section);
  }

  static createSection(section) {
    return FetchHelper.post(`${window.Api.Sections}`, section)
  }

  static deleteSection(id) {
    return FetchHelper.delete(`${window.Api.Sections}/${id}`)
  }

  static getComponentTypes() {
    return FetchHelper.get(`${window.Api.ComponentTypes}`);
  }
  static createParentMappedItem(parentItem){
    return FetchHelper.post(`${window.Api.MDMParentItems}`, parentItem)
  }

  static createChildMappedItem(childItem){
    return FetchHelper.post(`${window.Api.MDMChildItems}`, childItem)

  }

  static refreshParentMappedItem(item) {
    return FetchHelper.post(`${window.Api.MDMParentItems}/${item.id}/refresh`, {})
  }

  static refreshChildMappedItem(item) {
    return FetchHelper.post(`${window.Api.MDMChildItems}/${item.id}/refresh`, {})
  }

  static deleteChildMappedItem(item) {
    return FetchHelper.delete(`${window.Api.MDMChildItems}/${item.id}`)
  }
}
