import React from 'react'

import BaseTable from './BaseTable'
import DraggableTable from './DraggableTable/DraggableTable'
import DateDisplayCell from './cells/DateDisplayCell'
import SectionActionsCell from './cells/SectionActionsCell'

export default class SectionsTable extends React.Component {
  _getColumns() {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 200
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 70
      },
      // {
      //   Header: 'Content Type',
      //   accessor: 'content_type'
      // },
      {
        Header: 'Updated at',
        accessor: 'updated_at',
        Cell: rowInfo => {
          return <DateDisplayCell dateValue={rowInfo['value']} />
        }
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: rowInfo => {
          return <DateDisplayCell dateValue={rowInfo['value']} />
        }
      },
      {
        id: 'active',
        Header: 'Actions',
        width: 280,
        Cell: rowInfo => {
          if (this.props.screen) {
            return <SectionActionsCell
              in_set={true}  
              section={rowInfo}
              onRemoveClick={section => this.props.onRemoveSectionClick(section)}
            />
          }
          return <SectionActionsCell
            section={rowInfo.original}
            onManageComponents={section => this.props.onManageComponentsClick(section)}
            onEditClick={section => this.props.onEditSectionClick(section)}
            onDeleteClick={section => this.props.onDeleteSectionClick(section)}
          />  
        }
      }
    ]
  }

  render() {
    const columns = this._getColumns();
    const { screen } = this.props;
    let sections_in_screen;
    if (screen) {
      sections_in_screen = screen.sections.map(({ section }, index) => {
        let s = Object.assign({}, section);
        s.order = screen.sections[index].order;
        return s;
      });
    }
  
    return (
      <div>
        {this.props.screen ? 
          <DraggableTable
            data={sections_in_screen}
            columns={columns}
            onUpdateOrder={this.props.onUpdateOrderInScreen}
            onDataReceived={data => {
              this.setState({ data })
            }}
          /> :
          <BaseTable
            endpoint={window.Api.Sections}
            columns={columns}
            onDataReceived={data => {
              this.setState({ data })
            }}
          />
        }
      </div>
    )
  }
}
