import React from "react"

import Card from './components/cards/Card'
import SectionsTable from './components/tables/SectionsTable'
import Backend from '../utils/Backend'

import AddSectionToScreenModal from "./components/modal/AddSectionToScreenModal"

export default class SectionsInScreen extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      sections: [],
      screen: null,
      showAddModal: false,
      searchTerm: '',
      statusFilters: [],
    }
  }

  componentDidMount(){
    this._loadSections();
    this._loadScreen();
  }

  _loadSections() {
    Backend.getAllSections().then((data) => {
      this.setState({sections: data.results})
    }).catch(error => alert(error.message));
  }

  _loadScreen(){
    let id = this.props.match.params.screen_id;
    if(!id) this.props.history.push("/cms/screens");
    Backend.getScreen(id).then((data) => {
      if (data) {
        this.setState({
          screen: data,
          loading: false
        })
      }
    }).catch(error => alert(error.message));
  }

  replaceSectionWithSectionId(sections) {
    for (let section = 0; section < sections.length; ++section) {
      sections[section].section = sections[section].section.id;
      delete sections[section].screen;
      delete sections[section].id;
    }
  }

  _onUpdateOrderInScreen = (data) => {
    const copy_screen = Object.assign({}, this.state.screen);
    const sections_copy = [];
    data.forEach((section) => {
      sections_copy.push({
        section: section.id, order: section.order
      });
    });
    copy_screen.sections = sections_copy;
    Backend.editScreen(copy_screen).catch(error => alert(error.message));
  }

  render() {
    let {
      loading,
      showAddModal,
      sections,
      screen,
    } = this.state

    if (loading) {
      return null
    }

    return (
      <div>
        <div className="row">
          <Card
            title={screen === null ? "Sections" : "Screen: " + screen.name}
            className="col-xl-12"
            renderToolbar={() => {
              return (
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    &nbsp;
                    <div className="dropdown dropdown-inline">
                      <button
                        type="button"
                        className="btn btn-brand btn-icon-sm"
                        onClick={() => {this.setState({showAddModal: true})}}
                      >
                        <i className="flaticon2-plus"></i> Add Section
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          >
            <SectionsTable
              screen={screen}
              onUpdateOrderInScreen={this._onUpdateOrderInScreen}
              onRemoveSectionClick={section => {
                this.replaceSectionWithSectionId(screen.sections);
                for(let index = 0; index < screen.sections.length; ++index) {
                  if (screen.sections[index].section === section.id) {
                    screen.sections.splice(index, 1);
                    break;
                  }
                }
                Backend.editScreen(screen)
                .then(() => {
                  this.props.history.push(`/cms/sections/${screen.id}`)
                  document.location.reload();
                })
                .catch((error) => {
                  alert(error.message);
                  document.location.reload();
                });
              }}
            />
          </Card>
          <AddSectionToScreenModal
            show={showAddModal}
            sections={sections}
            screen={screen}
            onSubmit={() => {
              this.setState({ showAddModal: false })
              document.location.reload();
            }}
            onHide={() => this.setState({ showAddModal: false })}
          />
        </div>
      </div>
    )
  }
}
