import React from "react"

import InsuranceDetail from './components/InsuranceDetail';

import Backend from '../utils/Backend'

export default class EditInsurance extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      insurance: null,
      loading: true,
    }
  }

  componentDidMount(){
    this._loadInsurance()
  }

  _loadInsurance(){
    let id = this.props.match.params.insurance_id
    
    if(!id){
      this.setState({ loading: false })
      return
    }

    Backend.getInsurance(id)
    .then(({ results }) => {
      const insurance = results[0];
      this.setState({
        insurance,
        loading: false
      })
    })
  }

  _goToInsurance(){
    this.props.history.push(`/insurance`)
  }

  render() {
    let {
      insurance,
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <InsuranceDetail
        insurance={insurance}
        goToInsurance={() => this._goToInsurance()}
      />
    )
  }
}
