import React from 'react'

export default class SectionActionsCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      section: props.section
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderManage(section) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-primary btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onManageComponents(section)
        }}
      >
        Components
      </a>
    )
  }

  _renderEdit(section) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-primary btn-bold"
        style={{ marginLeft: '10px'}}
        onClick={e => {
          e.preventDefault()
          this.props.onEditClick(section)
        }}
      >
        Edit
      </a>
    )
  }

  _renderDelete(section) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-danger btn-bold"
        style={{ marginLeft: '10px'}}
        onClick={e => {
          e.preventDefault()
          this.props.onDeleteClick(section)
        }}
      >
        Delete
      </a>
    )
  }

  _renderRemove(section) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-danger btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onRemoveClick(section)
        }}
      >
        Remove
      </a>
    )
  }

  render() {
    let { section } = this.state;
    if (this.props.in_set) {
      return (
        <span style={{ overflow: 'visible', position: 'relative', width: '110px' }}>
          { this._renderRemove(section) }
        </span>
      );
    }
    return (
      <span style={{ overflow: 'visible', position: 'relative', width: '110px' }}>
        {this._renderManage(section)}
        {this._renderEdit(section)}
        {this._renderDelete(section)}
      </span>
    )
  }
}
