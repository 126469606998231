import React from 'react'

export default class FilterActionsCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: props.filter
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderRemove(filter) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-danger btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onRemoveFilterClick(filter)
        }}
      >
        Remove
      </a>
    )
  }

  render() {
    let { filter } = this.state
    return (
      <span
        style={{ overflow: 'visible', position: 'relative', width: '110px' }}
      >
        {this._renderRemove(filter)}
      </span>
    )
  }
}
