import React from "react";

const MySelect = ({ options, value, disabled, onChangeSelect }) => {
  const makeUpperCase = (label) => {
    return label[0].toUpperCase() + label.slice(1);
  }
  
  const renderOption = () => {
    return options.map((option) => {
      return <option key={ option.value } value={ option.value }>{ makeUpperCase(option.label) }</option>;
    });
  }

  return (
      <select
          className="form-control"
          value={ value }
          onChange={onChangeSelect}
          disabled={disabled}
      >
        <option value="" disabled>Please Select</option>
        { renderOption() }
      </select>
  );
};

export default MySelect;