import React from "react"

import Div from './layouts/Div'

import ImageEdit from './common/ImageEdit'

import Backend from '../../utils/Backend'

export default class CollectionDetail extends React.Component {
  constructor(props){
    super(props)

    let addMode = props.collection == null

    if (props.collection && !props.collection.collection_set) {
      props.collection.collection_set = "";
    }

    this.state = {
      addMode,
      collection: props.collection || { 
        name: "",
        description: "",
        collection_set_id: "" 
      },
    }
  }


  _isValid(data){
    let error = null;
    const {
      name,
      description
    } = data;
    
    if (name === "") error = "Enter name please!";
    if (description === "") error = "Enter description please!";

    if (error) {
      alert(error);
      return false;
    }

    return true
  }

  _handleSave(){
    let {
      collection
    } = this.state

    if(!this._isValid(collection)){
      return
    }

    this._save()
  }

  _save(){
    let {
      collection,
      addMode
    } = this.state

    this.setState({loading: true})

    let modifiedCollection = collection.filters ? { ...collection, filters: collection.filters.map((filter) => (typeof filter === 'object' && filter !== null ? filter.id : filter)) } : { ...collection };

    this._updateBackend(addMode, modifiedCollection)
    .then(collection => {
      this.setState({
        collection,
        loading: false,
        addMode: false
      })
      this.props.goToCollections();
    })
    .catch(error => {
      alert(error.message)
      this.setState({loading: false})
    })
  }

  _updateBackend(addMode, collection){
    if (addMode) return Backend.createCollection(collection)
    else return Backend.editCollection(collection)
  }

  _onUpdateBackgroundImage(image) { 
    let collection = this.state.collection;
    collection.background_image = image;
    this.setState({
      collection
    });
  }

  render() {
    let {
      addMode,
      collection,
      loading
    } = this.state

    let title = addMode ? "Save" : "Update"


    return (
      <div className="row">
				<div className="col-md-6">
					<Div className="kt-portlet" disabled={loading}>
						<div className="kt-portlet__head">
							<div className="kt-portlet__head-label">
								<h3 className="kt-portlet__head-title">
                  Collection
								</h3>
							</div>
						</div>


						<form className="kt-form">
							<div className="kt-portlet__body">
								<h5 className="kt-section__title">Details:</h5><br/>

								<div className="form-group">
									<label>Name</label>
									<input type="text" className="form-control"
                    value={collection.name}
                    onChange={e => {
                      collection.name = e.target.value
                      this.setState({ collection })
                    }}
                    placeholder="Name"
                  />
								</div>

                <div className="form-group">
									<label>Type</label>
									<input type="text" className="form-control"
                    value={collection.type}
                    onChange={e => {
                      collection.name = e.target.value
                      this.setState({ collection })
                    }}
                    placeholder="Type"
                  />
								</div>

                <div className="form-group">
									<label>Description</label>
                  <textarea value={collection.description}
                    onChange={e => {
                      collection.description = e.target.value
                      this.setState({ collection })
                    }}
                    placeholder="Description" className="form-control" />
								</div>

                <div className="form-group">
									<label>Status</label>
                  <select
                    className="form-control m-t-0 b-space"
                    value={collection.status}
                    onChange={e => {
                      let value = e.target.value
                      if (value === "") {
                        value = null
                      }
                      collection.status = value
                      this.setState({ collection })
                    }}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">In-Active</option>
                  </select>
								</div>

                <div className="form-group">
									<label>Type</label>
                  <select
                    className="form-control m-t-0 b-space"
                    value={collection.type}
                    onChange={e => {
                      let value = e.target.value
                      if (value === "") {
                        value = null
                      }
                      collection.type = value
                      this.setState({ collection })
                    }}
                  >
                    <option value="">Collection</option>
                    <option value="story">Story</option>
                  </select>
								</div>

                <div className="form-group">
                  <label>Background Image</label>
                  <ImageEdit image={collection.background_image} onUpdated={this._onUpdateBackgroundImage.bind(this)} />
                </div>
							</div>

							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<button type="reset" onClick={() => this._handleSave()} className="btn btn-primary">
                    { title }
                  </button>
								</div>
							</div>
              
						</form>
					</Div>
				</div>
			</div>
    )
  }
}
