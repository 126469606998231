import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from '../../../utils/Backend'
import MySelect from '../common/MySelect'

export default class AddCollectionToSetModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      selected_collection: {id: ""},
      collections: [],
      collection_set: null,
      loading: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _submit(){
    let {
      selected_collection
    } = this.state

    if (selected_collection.id === "") {
      alert("Please select a Collection")
      return
    }

    this.setState({ loading: true })
    let { collection_set } = this.state;
    for (let collection = 0; collection < collection_set.collections.length; ++collection) {
      collection_set.collections[collection].collection = collection_set.collections[collection].collection.id;
    }
    
    collection_set.collections.push({
      collection: selected_collection.id,
      order: 1
    });
    Backend.editCollectionSet(collection_set)
    .then(() => {
      this.setState({ loading: false })
      this.props.onSubmit()
    }).catch(error => {
      this.setState({ loading: false })
      alert(error.message)
      this.props.onHide()
    })
  }

  _getCollectionTextOptions() {
    return this.state.collections.map((collection) => {
      return {
        label: collection.name, value: collection.id
      }
    });
  }

  render() {
    let {
      show,
      loading,
      collections,
      selected_collection
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Collection</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group mt-3">
            <label>Collection</label><br/>
            <MySelect
              value={selected_collection.id}
              options={this._getCollectionTextOptions()}
              onChangeSelect={(e) => {
                for (let collection of collections) {
                  if (e.target.value == collection.id) {
                    this.setState({selected_collection: collection})
                    break;
                  }
                }
                
              }}
            />
          </div>
        </Modal.Body>

        { !loading &&
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}>Cancel</button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this._submit()}>Submit</button>
        </Modal.Footer>
        }
      </Modal>
    )
  }
}
