import React from 'react'
import DraggableTable from './DraggableTable/DraggableTable'

import ComponentActionsCell from './cells/ComponentActionsCell'
import DateDisplayCell from './cells/DateDisplayCell'

export default class ComponentsTable extends React.Component {
  _getColumns() {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 200
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 70
      },
      {
        Header: 'Updated at',
        accessor: 'updated_at',
        Cell: rowInfo => {
          return <DateDisplayCell dateValue={rowInfo['value']} />
        }
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: rowInfo => {
          return <DateDisplayCell dateValue={rowInfo['value']} />
        }
      },
      {
        id: 'active',
        Header: 'Actions',
        width: 150,
        Cell: rowInfo => {
          if (this.props.section) {
            return <ComponentActionsCell
              in_section={true}  
              component={rowInfo}
              onRemoveClick={component => this.props.onRemoveComponentClick(component)}
            />
          }
        }
      }
    ]
  }

  render() {
    const columns = this._getColumns();
    const { section } = this.props;
    let components = [];
    if (section) {
      components = section.components.map(({ component_details }, index) => {
        let c = Object.assign({}, component_details);
        c.order = section.components[index].order;
        c.type = section.components[index].type;
        return c;
      });
    }
  
    return (
      <div>
        <DraggableTable
          data={components}
          columns={columns}
          onUpdateOrder={this.props.onUpdateOrderInSection}
          onDataReceived={data => {
            this.setState({ data })
          }}
        /> 
      </div>
    )
  }
}
