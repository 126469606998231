import React from "react"

import Card from './components/cards/Card'
import Backend from '../utils/Backend'

import FiltersTable from './components/tables/FiltersTable'
import AddFilterModal from './components/modal/AddFilterModal'

export default class Filters extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      showAddModal: false,
      collection: {},
      searchTerm: '',
      statusFilters: [],
      refresh: false,
    }
  }

  componentDidMount() {
    this._loadCollection();
  }

  _loadCollection = () => {
    let id = this.props.match.params.collection_id;
    if (!id) this.props.history.push("/collection/collections");
    this.setState({ refresh: false });
    Backend.getCollection(id).then((data) => {
      if (data) {
        this.setState({
          collection: data,
          loading: false,
          refresh: true
        })
      }
    }).catch(e => {
      alert("Cannot Load Collection: " + e);
      this.props.history.push("/collection/collections");
    });
  };

  render() {
    let {
      showAddModal,
      collection,
      loading
    } = this.state

    if (loading) {
      return null
    }

    return (
      <div>
        <div className="row">
          <Card
            title={collection.name ? collection.name : "Filters"}
            className="col-xl-12"
            renderToolbar={() => {
              return (
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    &nbsp;
                    <div className="dropdown dropdown-inline">
                      <button
                        type="button"
                        className="btn btn-brand btn-icon-sm"
                        onClick={() => this.setState({ showAddModal: true })}
                      >
                        <i className="flaticon2-plus"></i> New Filter
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          >
            {this.state.refresh ? <FiltersTable
              collection={collection}
              onRemoveFilterClick={filter => {
                const index = collection.filters.findIndex((f) => f.id === filter.id);
                if (index > -1) {
                  collection.filters.splice(index, 1);
                }
                Backend.editCollection({ ...collection, filters: collection.filters.map((f) => f.id) })
                  .then(() => { this._loadCollection() })
                  .catch(error => { alert(error.message) });
              }}
            /> : <></>}
          </Card>

          <AddFilterModal
            show={showAddModal}
            collection={collection}
            onSubmit={() => {
              this._loadCollection()
              this.setState({ showAddModal: false })
            }}
            onHide={() => this.setState({ showAddModal: false })}
          />
        </div>
      </div>
    )
  }
}
