import React from 'react'

export default class CollectionActionsCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collection: props.collection
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderManage(collection) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-primary btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onManageFiltersClick(collection)
        }}
      >
        Filters
      </a>
    )
  }

  _renderEdit(collection) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-primary btn-bold"
        style={{ marginLeft: '10px'}}
        onClick={e => {
          e.preventDefault()
          this.props.onEditClick(collection)
        }}
      >
        Edit
      </a>
    )
  }

  _renderDelete(collection) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-danger btn-bold"
        style={{ marginLeft: '10px'}}
        onClick={e => {
          e.preventDefault()
          this.props.onDeleteClick(collection)
        }}
      >
        Delete
      </a>
    )
  }

  _renderRemove(collection) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-danger btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onRemoveClick(collection)
        }}
      >
        Remove
      </a>
    )
  }

  render() {
    let { collection } = this.state;
    if (this.props.in_set) {
      return (
        <span style={{ overflow: 'visible', position: 'relative', width: '110px' }}>
          { this._renderRemove(collection) }
        </span>
      );
    }
    return (
      <span style={{ overflow: 'visible', position: 'relative', width: '110px' }}>
        {this._renderManage(collection)}
        {this._renderEdit(collection)}
        {this._renderDelete(collection)}
      </span>
    )
  }
}
