import React from 'react'

import BaseTable from './BaseTable'

import FilterActionsCell from './cells/FilterActionsCell'

export default class FiltersTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  _getColumns() {
    return [
      {
        Header: 'Filter Type',
        accessor: 'filter_type',
        Cell: rowInfo => {
          return (
            <>{(rowInfo['value'] || "").charAt(0).toUpperCase() + (rowInfo['value'] || "").slice(1)}</>
          )
        }
      },
      {
        Header: 'Key',
        accessor: 'key'
      },
      {
        Header: 'Value',
        accessor: 'value'
      },
      {
        id: 'active',
        Header: 'Actions',
        width: 180,
        Cell: rowInfo => {
          return (
            <FilterActionsCell
              filter={rowInfo.original}
              onRemoveFilterClick={filter => this.props.onRemoveFilterClick(filter)}
            />
          )
        }
      }
    ]
  }

  render() {
    const columns = this._getColumns()
    return (
      <div>
        <BaseTable
          endpoint={`${window.Api.Filters}?collection=${this.props.collection.id}`}
          columns={columns}
          onDataReceived={data => {
            this.setState({ data })
          }}
        />
      </div>
    )
  }
}
