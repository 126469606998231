import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import LeftMenu from './components/LeftMenu'
import MobileHeader from './components/MobileHeader'
import Footer from './components/Footer'
import Header from './components/Header'
import SubHeader from './components/SubHeader'

import Overview from './Overview'
import Dealers from './Dealers'
import EditDealer from './EditDealer'
import Vehicles from './Vehicles'
import PrivateVehicles from './PrivateVehicles'
import Customers from './Customers'
import Adverts from './Adverts'
import EditAdvert from './EditAdvert'
import Insurance from './Insurance'
import EditInsurance from './EditInsurance'
import SkupeNetDealers from './SkupeNetDealers'
import Bodies from './Bodies'
import MDMUnmappedItems from "./MDM/"
import MDMParentItems from "./MDM/ParentItems"
import MDMChildItems from "./MDM/ChildItems"
import CollectionSets from './CollectionSets'
import EditCollectionSet from './EditCollectionSet'
import CollectionsInSet from './CollectionsInSet'
import Collections from './Collections'
import EditCollection from './EditCollection'
import Filters from './Filters'
import Screens from './Screens'
import EditScreen from './EditScreen'
import Sections from './Sections'
import EditSection from './EditSection'
import SectionInScreen from './SectionsInScreen'
import ComponentsInSection from './ComponentsInSection'
import MarketingMessages from './MarketingMessages'
import EditMarketingMessage from './EditMarketingMessage'

export default class App extends React.Component {
  render() {
    return (
      <div>
        <MobileHeader />

        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <LeftMenu />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <SubHeader />

                {/* begin:: Content */}
                <div
                  className="kt-content  kt-grid__item kt-grid__item--fluid"
                  id="kt_content"
                >
                  <Switch>
                    <Route exact path="/" component={Overview} />
                    <Route exact path="/dealers" component={Dealers} />
                    <Route exact path="/adverts" component={Adverts} />
                    <Route exact path="/adverts/:advert_id" component={EditAdvert} />
                    <Route exact path="/create-advert" component={EditAdvert} />
                    <Route exact path="/insurance" component={Insurance} />
                    <Route exact path="/insurance/:insurance_id" component={EditInsurance} />
                    <Route exact path="/create-insurance" component={EditInsurance} />
                    <Route exact path="/dealers/:dealer_id" component={EditDealer} />
                    <Route exact path="/dealer-listings" component={Vehicles} />
                    <Route exact path="/private-listings" component={PrivateVehicles}/>
                    <Route exact path="/skupenet-dealers" component={SkupeNetDealers} />
                    <Route exact path="/users" component={Customers} />
                    <Route exact path="/segments" component={Bodies} />
                    <Route exact path="/mdm-unmapped" component={MDMUnmappedItems} />
                    <Route exact path="/mdm-parent" component={MDMParentItems} />
                    <Route exact path="/mdm-child" component={MDMChildItems} />
                    <Route exact path="/collection/collection-sets" component={CollectionSets} />
                    <Route exact path="/collection/collection-set/:collection_set_id" component={EditCollectionSet} />
                    <Route exact path="/collection/create-collection-set" component={EditCollectionSet} />
                    <Route exact path="/collection/collections/:collection_set_id" component={CollectionsInSet} />
                    <Route exact path="/collection/collections" component={Collections} />
                    <Route exact path="/collection/collection/:collection_id" component={EditCollection} />
                    <Route exact path="/collection/create-collection" component={EditCollection} />
                    <Route exact path="/collection/filters/:collection_id" component={Filters} />
                    <Route exact path="/cms/screens" component={Screens} />
                    <Route exact path="/cms/screen/:screen_id" component={EditScreen} />
                    <Route exact path="/cms/create-screen" component={EditScreen} />
                    <Route exact path="/cms/sections/:screen_id" component={SectionInScreen} />
                    <Route exact path="/cms/sections" component={Sections} />
                    <Route exact path="/cms/section/:section_id" component={EditSection} />
                    <Route exact path="/cms/create-section" component={EditSection} />
                    <Route exact path="/cms/components/:section_id" component={ComponentsInSection} />

                    <Route exact path="/marketing-messages" component={MarketingMessages} />
                    <Route exact path="/marketing-message/:message_id" component={EditMarketingMessage} />
                    <Route exact path="/marketing-messages/create-message" component={EditMarketingMessage} />
                    <Redirect to="/404" />
                  </Switch>

                  {/*  end:: Content  */}
                </div>
                {/*  begin:: Footer  */}
                <Footer />
                {/*  end:: Footer  */}
              </div>
            </div>
          </div>
          {/*  end:: Page  */}
          {/*  begin::Scrolltop  */}
          <div id="kt_scrolltop" className="kt-scrolltop">
            <i className="fa fa-arrow-up" />
          </div>
        </div>
      </div>
    )
  }
}
