import React from "react"

export default class Footer extends React.Component {
  render() {
    return (
      <div className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
				<div className="kt-footer__copyright">
					2019&nbsp;&copy;&nbsp;sweep
				</div>
			</div>
    )
  }
}
