import React from 'react'

export default class MarketingMessageCell extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            marketingMessage: props.marketingMessage
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }


    _renderEdit(marketingMessage) {
        return (
            <a
                href="#"
                className="btn btn-sm btn-primary btn-bold"
                style={{ marginLeft: '10px' }}
                onClick={e => {
                    e.preventDefault()
                    this.props.onEditClick(marketingMessage)
                }}
            >
                Edit
            </a>
        )
    }

    _renderDelete(marketingMessage) {
        return (
            <a
                href="#"
                className="btn btn-sm btn-danger btn-bold"
                style={{ marginLeft: '10px' }}
                onClick={e => {
                    e.preventDefault()
                    this.props.onDeleteClick(marketingMessage)
                }}
            >
                Delete
            </a>
        )
    }

    render() {
        let { marketingMessage } = this.state;

        return (
            <span style={{ overflow: 'visible', position: 'relative', width: '110px' }}>
                {this._renderEdit(marketingMessage)}
                {this._renderDelete(marketingMessage)}
            </span>
        )
    }
}
