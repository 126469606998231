import React from 'react'

export default class CustomerActionsCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: { ...props.customer }, // Assuming customer is passed as a prop
      status: props.customer.user.status, // Assuming customer has a status
    };
  }

  handleStatusChange = (e) => {
    const newStatus = e.target.value;
    const updatedCustomer = {
      ...this.state.customer,
      user: {
        ...this.state.customer.user,
        status: newStatus,
      },
    };
  
    this.setState({ customer: updatedCustomer, status: newStatus }, () => {
      // Notify the parent component of the status change
      if (this.props.onStatusChange) {
        this.props.onStatusChange(updatedCustomer);
      }
    });
  };
  

  render() {
    const { status } = this.state;

    return (
      <select
        value={status}
        onChange={this.handleStatusChange}
        className={`btn btn-sm btn-label-${this.props.label} btn-bold`}
      >
        <option value="active">Active</option>
        <option value="banned">Suspended</option>
        <option value="limited-account">Limited</option> {/* Ensure this matches your status value */}
      </select>
    );
  }
}
