import React from "react"

import Vehicles from './Vehicles'

import Backend from '../utils/Backend'

export default class PrivateVehicles extends React.Component {
  render() {
    return (
      <Vehicles dealerMode={false}/>
    )
  }
}
