import React from "react"
import Switch from "react-switch";

import Card from '../cards/Card'
import Div from '../layouts/Div'

import ImageEdit from '../common/ImageEdit'

import LocationInput from '../common/LocationInput'

import StaffDetail from './StaffDetail'

import Backend from '../../../utils/Backend'

export default class DealerDetail extends React.Component {
  constructor(props){
    super(props)
    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps));
  }

  _getState(props){
    return {
      dealer: props.dealer
    }
  }

  _renderMembers(){
    let {
      id, dealer
    } = this.state
    let showDelete = dealer.staff.length > 1;

    let staff = [
      ...dealer.staff,
      {
        user: {}
      }
    ]

    return staff.map((member, index) => {
      console.log(member);
      return (
        <StaffDetail
          key={index}
          dealerId = {id}
          dealer={dealer}
          staff={member}
          showDelete={showDelete}
          onDeleteClicked={() => this._handleDeleteMember(index)}
          onAdded={member => {
            dealer.staff.push(member)
            this.setState({ dealer })
          }}
        />
      )
    })
  }

  _addMember(){
    let {
      dealer
    } = this.state

    this.setState({
      staff: [
        ...dealer.staff,
        {
          user:{}
        }
      ]
    })
  }

  _handleDeleteMember(index){
    let {
      dealer
    } = this.state

    if(dealer.staff.length == 1){
      return
    }

    dealer.staff.splice(index, 1)

    this.setState({ dealer })
  }

  _isValid(data){
    let error = null;

    if (!data.name){
      error = "You must include a name"
    }

    if(error){
      alert(error);
      return false;
    }
    return true
  }

  _handleUpdate(){
    let {
      dealer
    } = this.state

    if(!this._isValid(dealer)){
      return
    }

    this._update()
  }

  _update(){
    let {
      dealer
    } = this.state

    this.setState({loading: true})
    Backend.editDealer({
      ...dealer,
      dealer_type:
        dealer?.dealer_type === "independent_clear_product"
          ? "independent"
          : dealer?.dealer_type,
    })
      .then((dealer) => {
        this.setState({
          dealer,
          loading: false,
        });
      })
      .catch((error) => {
        alert(error.message);
        this.setState({ loading: false });
      });
  }

  _onUpdateProfileImage(image) { 
    let dealer = this.state.dealer;
    dealer.dealer_profile_image = image;
    this.setState({
      dealer
    });
  }

  _deleteProfileImage() {
    let dealer = this.state.dealer;
    dealer.dealer_profile_image = null;
    this.setState({
      dealer
    });
  }

  render() {
    let {
      dealer,
      loading
    } = this.state

    return (
      <div className="row">
				<div className="col-md-6">
					<Div className="kt-portlet" disabled={loading}>
						<div className="kt-portlet__head">
							<div className="kt-portlet__head-label">
								<h3 className="kt-portlet__head-title">
                  Dealer
								</h3>
							</div>
						</div>


						<form className="kt-form">
							<div key={dealer.staff.length} className="kt-portlet__body">
								<h5 className="kt-section__title">Details:</h5><br/>
                <div className="form-group">
                  <label>Profile Image</label>
                  <ImageEdit image={dealer.dealer_profile_image} onUpdated={this._onUpdateProfileImage.bind(this)} />
                  <button onClick={() => {this._deleteProfileImage()}} type="button" className="btn btn-danger">Delete Image</button>
                </div>
								<div className="form-group">
									<label>Name</label>
									<input type="text" className="form-control"
                    value={dealer.name}
                    onChange={e => {
                      dealer.name = e.target.value
                      this.setState({ dealer })
                    }}
                    placeholder="Name"
                  />
								</div>

                <div className="form-group">
									<label>Phone</label>
									<input type="text" className="form-control"
                    value={dealer.phone}
                    onChange={e => {
                      dealer.phone = e.target.value
                      this.setState({ dealer })
                    }}
                    placeholder="Phone"
                  />
								</div>
                <div className="form-group">
									<label>WhatsApp Number</label>
									<input type="text" className="form-control"
                    value={dealer.whatsapp_number}
                    onChange={e => {
                      dealer.whatsapp_number = e.target.value
                      this.setState({ dealer })
                    }}
                    placeholder="WhatsApp Number Format: (353XXXXXXXXX)"
                  />
								</div>
                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span><label>Prospects</label></span>
                    <Switch
                      height={14}
                      width={28}
                      checked={dealer.warmleads_enabled}
                      checkedIcon={null}
                      uncheckedIcon={null}
                      onColor="#5d78ff"
                      onChange={checked => {
                        console.log('Prospects? ',checked)
                        dealer.warmleads_enabled = checked
                        this.setState({ dealer  })
                      }}
                    />
                  </label>
                </div>

                <div className="form-group">
									<label>Email Domain</label>
									<input type="text" className="form-control"
                    value={dealer.email_domain}
                    onChange={e => {
                      dealer.email_domain = e.target.value
                      this.setState({ dealer })
                    }}
                    placeholder="Email Domain - e.g. amazingmotors.ie"
                  />
								</div>

                <div className="form-group">
                  <label>Location</label>
                  <LocationInput
                    location={dealer.location}
                    onUpdated={location => {
                      let updatedLocation = {
                        ...dealer.location,
                        ...location
                      }
                      dealer.location = updatedLocation
                      this.setState({ dealer })
                    }}/>
                </div>


                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span><label>Finance Application</label></span>
                    <Switch
                      height={14}
                      width={28}
                      checked={dealer.finance_application_active}
                      checkedIcon={null}
                      uncheckedIcon={null}
                      onColor="#5d78ff"
                      onChange={checked => {
                        dealer.finance_application_active = checked
                        this.setState({ dealer  })
                      }}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span><label>Show Email Address & Telephone Number from Finance Applications</label></span>
                    <Switch
                      height={14}
                      width={28}
                      checked={dealer.can_see_user_contact_info_in_finance_application}
                      checkedIcon={null}
                      uncheckedIcon={null}
                      onColor="#5d78ff"
                      onChange={checked => {
                        dealer.can_see_user_contact_info_in_finance_application = checked
                        this.setState({ dealer  })
                      }}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span><label>Send user emails in regular leads</label></span>
                    <Switch
                      height={14}
                      width={28}
                      checked={dealer.can_see_user_email}
                      checkedIcon={null}
                      uncheckedIcon={null}
                      onColor="#5d78ff"
                      onChange={checked => {
                        dealer.can_see_user_email = checked
                        this.setState({ dealer  })
                      }}
                    />
                  </label>
                </div>

                

                <div className="form-group">
									<label>Credit Intermediary Source</label>
                  <select
                    className="form-control"
                    value={dealer.credit_intermediary_source}
                    onChange={e => {
                      dealer.credit_intermediary_source = e.target.value
                      this.setState({ dealer })
                    }}
                  >
                    <option value=""> </option>
                    <option value="Dealer">Dealer</option>
                    <option value="Loanitt">Loanitt</option>
                    <option value="Bluestone">Bluestone</option>
                  </select>
								</div>

                {dealer.credit_intermediary_source && dealer.credit_intermediary_source === 'Bluestone' ? <div className="form-group">
                  <label>Bluestone Email</label>
                  <input type="text" className="form-control"
                    value={dealer.bluestone_email}
                    onChange={e => {
                      dealer.bluestone_email = e.target.value
                      this.setState({ dealer })
                    }}
                    placeholder="Bluestone Email"
                  />
                </div> : null}

                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span><label>Messaging Enabled</label></span>
                    <Switch
                      height={14}
                      width={28}
                      checked={dealer.can_receive_user_message}
                      checkedIcon={null}
                      uncheckedIcon={null}
                      onColor="#5d78ff"
                      onChange={checked => {
                        dealer.can_receive_user_message = checked
                        this.setState({ dealer  })
                      }}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span><label>Map Enabled</label></span>
                    <Switch
                      height={14}
                      width={28}
                      checked={dealer.show_map}
                      checkedIcon={null}
                      uncheckedIcon={null}
                      onColor="#5d78ff"
                      onChange={checked => {
                        dealer.show_map = checked
                        this.setState({ dealer  })
                      }}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span><label>Simi</label></span>
                    <Switch
                      height={14}
                      width={28}
                      checked={dealer.is_simi}
                      checkedIcon={null}
                      uncheckedIcon={null}
                      onColor="#5d78ff"
                      onChange={checked => {
                        dealer.is_simi = checked;
                        this.setState({ dealer });
                      }}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span><label>WhatsApp</label></span>
                    <Switch
                      height={14}
                      width={28}
                      checked={dealer.whatsapp_enabled}
                      checkedIcon={null}
                      uncheckedIcon={null}
                      onColor="#5d78ff"
                      onChange={checked => {
                        dealer.whatsapp_enabled = checked;
                        this.setState({ dealer });
                      }}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span><label>Home Devilery</label></span>
                    <Switch
                      height={14}
                      width={28}
                      checked={dealer.home_delivery}
                      checkedIcon={null}
                      uncheckedIcon={null}
                      onColor="#5d78ff"
                      onChange={checked => {
                        dealer.home_delivery = checked;
                        this.setState({ dealer });
                      }}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    <span><label>Click & Collect</label></span>
                    <Switch
                      height={14}
                      width={28}
                      checked={dealer.click_collect}
                      checkedIcon={null}
                      uncheckedIcon={null}
                      onColor="#5d78ff"
                      onChange={checked => {
                        dealer.click_collect = checked;
                        this.setState({ dealer });
                      }}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <label>Rank</label>
                  <select
                    className="form-control"
                    value={dealer.rank}
                    onChange={e => {
                      dealer.rank = e.target.value
                      this.setState({ dealer })
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Feed Source</label>
                  <select
                    className="form-control"
                    value={dealer.source}
                    onChange={e => {
                      dealer.source = e.target.value
                      dealer.pending_feed_change = true
                      this.setState({ dealer })
                    }}
                  >
                    <option value="">( Please Select a Feed )</option>
                    <option value="simi">Simi</option>
                    <option value="cars_ireland">Cars Ireland</option>
                    <option value="skupenet">Skupenet</option>
                    <option value="beep_beep">Beep Beep</option>
                    <option value="happydealer">Happydealer</option>
                  </select>
                  <br></br>
                  <label> Old active vehicles will be unpublished and the new feed activated when the feed tasks run overnight.
                   <br></br> For a feed task to be run manually, please ask the Engineering Team.
                  </label>
                </div>

                <div className="form-group">
                  <label>Required Vehicle Images number to display in feed</label>
                  <input type="text" className="form-control"
                    value={dealer.min_vehicle_images_for_feed}
                    onChange={e => {
                      dealer.min_vehicle_images_for_feed = e.target.value
                      this.setState({ dealer })
                    }}
                  />
                </div>


                <div className="form-group">
									<label>Eskimo API Key</label>
									<input type="text" className="form-control"
                    value={dealer.eskimo_api_key || ''}
                    onChange={e => {
                      dealer.eskimo_api_key = e.target.value || null
                      this.setState({ dealer })
                    }}
                    placeholder="Eskimo API Key"
                  />
								</div>

                

							</div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<button type="reset" onClick={() => this._handleUpdate()} className="btn btn-primary">
                    Update
                  </button>
								</div>
							</div>
						</form>
					</Div>

          { this._renderMembers() }
				</div>
			</div>
    )
  }
}
