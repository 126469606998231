import React from 'react';
import BaseTable from '../BaseTable';
import 'react-base-table/styles.css'
import styled from 'styled-components'

import FetchHelper from '../../../../utils/FetchHelper'

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
const DraggableContainer = sortableContainer(({ children }) => children)
const DraggableElement = sortableElement(({ children }) => children)
const DraggableHandle = sortableHandle(({ children }) => children)

const Handle = styled.div`
  flex: none;
  width: 15px;
  height: 100%;

  &::before {
    content: '';
    border-left: 4px dotted #ccc;
    display: block;
    height: 20px;
    margin: 15px 3px;
  }

  &:hover::before {
    border-color: #888;
  }
`


const Row = ({ index, children }) => (
  <DraggableElement index={index}>
    <div className="draggable-row">
      <DraggableHandle>
        <Handle />
      </DraggableHandle>
      {children}
    </div>
  </DraggableElement>
)

class DnDTable extends React.Component {
  state = {
    data: []
  }

  componentWillReceiveProps(props) {
    props.data.sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
    this.setState({ data: props.data })
  }

  copyData = (data) => {
    const copy = [];
    data.forEach(piece => {
      copy.push(Object.assign({}, piece));
    });
    return copy;
  }

  table = React.createRef()

  getContainer = () => {
    return this.table.current;
  }

  getHelperContainer = () => {
    return this.table.current;
  }

  rowProps = args => {
    const extraProps = this.props.rowProps
    return {
      ...extraProps,
      tagName: Row,
      index: args.rowIndex,
    }
  }

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const data = [...this.state.data];

    const [removed] = data.splice(oldIndex, 1);
    data.splice(newIndex, 0, removed);  

    // const old_data = this.copyData(data);
    data.forEach((piece, index) => {
      if (piece.order) piece.order = index + 1;
    });
    this.props.onUpdateOrder(data);
    // data.forEach(piece => {
    //   old_data.forEach(old_piece => {
    //     if (piece.id === old_piece.id && piece.order !== old_piece.order) {
    //       old_piece.order = piece.order;
    //       this.props.onUpdateOrder(old_piece);
    //     }
    //   })
    // })

    this.setState({
      data: data,
    });
  }

  mapRow = (row) => {
    return this.props.columns.map((column, index) => {
      if (column.Header.includes("Image") && row[column.accessor]) {
        return (
          <div style={{ width: column.width, flex: column.width + " 0 auto"}} key={column.Header + " " + index} className={"draggable-td "}>
            <img src={row[column.accessor].original} alt="Image"/>
          </div>
        )
      }
      return (
        <div className={"draggable-td "} style={{ width: column.width, flex: column.width + " 0 auto"}} key={column.Header + " " + index}>
          {column.Header === "Actions" ? column.Cell(row) : column.Cell ? column.Cell(row) : row[column.accessor]}
        </div>
      ) 
    })
  }

  createRows = () => {
    return this.state.data.map((row, index) => {
      return (
        <Row key={row.id} index={index}>
          { this.mapRow(row) }
        </Row>
      )
    });
  }

  createColumns = () => {
    return this.props.columns.map((column, index) => {
      if (!column.width) column.width = 110;
      return <p className="draggable-td" style={{ width: column.width, flex: column.width + " 0 auto"}} key={column.Header + "-" + index}>{column.Header}</p>
    })
  }
  
  render() {
    return (
      <DraggableContainer
        useDragHandle
        getContainer={this.getContainer}
        helperContainer={this.getHelperContainer}
        onSortEnd={this.handleSortEnd}
      >
        <div className="draggable-table-holder">
          <div className="draggable-table" ref={this.table}>
            <div className="draggable-row draggable-main-row">
              {this.createColumns()}
            </div>
            <div className="data-rows-holder">
              {this.createRows()}
            </div>
          </div>
        </div>
      </DraggableContainer>
    )
  }
}




export default class DraggableTable extends BaseTable {
  state = {
    data: []
  }

  componentDidMount() {
    this._fetchData();
  }

  _fetchData() {
    if (this.props.endpoint) {
      FetchHelper.get(this.props.endpoint)
      .then(response => {
        let data = response.results;
        this.setState({ data });
      })
      .catch(error => {
        alert('Error', error)
      })
    }
    else this.setState({ data: this.props.data})
  }

  render () {
    return (
      <>
        <DnDTable 
          data={this.state.data}
          columns={this.props.columns}
          onUpdateOrder={this.props.onUpdateOrder}
        />
      </>
    )
  }
}
