import React from 'react'

export default class AdvertActionsCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      advert: props.advert
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderEdit(advert){
    return (
      <a href="#"
        className="btn btn-sm btn-label-warning btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onEditClick(advert)
        }}
      >
        Edit
      </a>
    )
  }

  _renderToggleActivate(advert){

    let label = advert.active ? "danger" : "info"
    let title = advert.active ? "Unpublish" : "Publish"

    return (
      <a href="#"
        className={`btn btn-sm btn-label-${label} btn-bold`}
        style={{ marginLeft: 10 }}
        onClick={e => {
          e.preventDefault()

          if(advert.active){
            this.props.onDeactivateClick({ ...advert })
          }
          else{
            this.props.onActivateClick({ ...advert })
          }

          advert.active = !advert.active

          this.setState({ advert })
        }}
      >
        { title }
      </a>
    )
  }


  render() {
    let { advert } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative', width: '110px'}}>
        { this._renderEdit(advert) }
        { this._renderToggleActivate(advert) }
      </span>
    )
  }
}
