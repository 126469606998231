import React from "react"

import DealerDetail from './components/dealer/DealerDetail'

import Backend from '../utils/Backend'

export default class EditDealer extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      dealer: null,
      loading: true,
    }
  }

  componentDidMount(){
    this._loadDealer()
  }

  _loadDealer(){
    Backend.getDealer(this.props.match.params.dealer_id)
    .then(dealer => {
      this.setState({
        dealer,
        loading: false
      })
    })
  }

  _goToDealers(){
    this.props.history.push(`/dealers`)
  }

  render() {
    let {
      dealer,
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <DealerDetail
        dealer={dealer}
        goToDealers={() => this._goToDealers()}
      />
    )
  }
}
