import React from "react"

import StatCard from './components/cards/StatCard'
import Card from './components/cards/Card'
import VehiclesTable from './components/tables/VehiclesTable'

import Backend from '../utils/Backend'

export default class Vehicles extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      dealerMode: props.dealerMode,
      loading: true,
      searchTerm: '',
      statusFilters: [],
    }
  }

  componentDidMount(){
    this._loadStats()
  }

  _loadStats(){
    let {
      dealerMode
    } = this.state
    Backend.getVehiclesStats()
    .then(stats => {

      let active_no       = dealerMode ? stats.active_dealer_no : stats.active_private_no
      let total_no        = dealerMode ? stats.total_dealer_no : stats.total_private_no
      let new_7_days_no   = dealerMode ? stats.new_7_days_dealer_no : stats.new_7_days_private_no
      let new_30_days_no  = dealerMode ? stats.new_30_days_dealer_no : stats.new_30_days_private_no

      this.setState({
        stats: {
          active_no,
          total_no,
          new_7_days_no,
          new_30_days_no
        },
        loading: false
      })
    })

  }

  render() {
    let {
      stats,
      loading,
      dealerMode
    } = this.state

    if(loading){
      return null
    }

    return (
      <div>
        <div className="row">
  				<StatCard
            title="Active"
            value={ stats.active_no }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          <StatCard
            title="Total"
            value={ stats.total_no }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          <StatCard
            title="New in Last 7 Days"
            value={ stats.new_7_days_no }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          <StatCard
            title="New in Last 30 Days"
            value={ stats.new_30_days_no }
            size="col-md-3 col-lg-3 col-xl-3"
          />
  			</div>
        <div className="row">
          <Card
            title="Private Listings"
            className="col-xl-12"
          >
            <VehiclesTable
              dealerMode={dealerMode}
              onDeactivatedVehicle={() => {
                stats.active_no -= 1
                this.setState({ stats })
              }}
              onActivatedVehicle={() => {
                stats.active_no += 1
                this.setState({ stats })
              }}
            />
          </Card>
        </div>
      </div>
    )
  }
}

Vehicles.defaultProps = {
  dealerMode: true
}
