import React from 'react'

export default class DealerActionsCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dealer: props.dealer
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _renderEdit(dealer) {
    return (
      <a
        href="#"
        className="btn btn-sm btn-label-success btn-bold"
        onClick={e => {
          e.preventDefault()
          this.props.onEditClick(dealer)
        }}
      >
        Edit
      </a>
    )
  }

  _renderToggleActivate(dealer) {
    let label = dealer.active ? 'danger' : 'info'
    let title = dealer.active ? 'Unpublish' : 'Publish'
    console.log(dealer.name, dealer.active)
    return (
      <a
        href="#"
        className={`btn btn-sm btn-label-${label} btn-bold`}
        style={{ marginLeft: 10 }}
        onClick={e => {
          e.preventDefault()

          if (dealer.active) {
            this.props.onDeactivateClick({ ...dealer })
          } else {
            this.props.onActivateClick({ ...dealer })
          }

          dealer.active = !dealer.active

          this.setState({ dealer })
        }}
      >
        {title}
      </a>
    )
  }

  render() {
    let { dealer } = this.state
    return (
      <span
        style={{ overflow: 'visible', position: 'relative', width: '110px' }}
      >
        {this._renderEdit(dealer)}
        {this._renderToggleActivate(dealer)}
      </span>
    )
  }
}
