import React from "react"

import Card from './components/cards/Card'
import ComponentsTable from './components/tables/ComponentsTable'
import Backend from '../utils/Backend'

import AddComponentToSectionModal from "./components/modal/AddComponentToSectionModal"

export default class ComponentsInSection extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      section: null,
      showAddModal: false,
      searchTerm: '',
      statusFilters: [],
      showComponents: false
    }
  }

  componentDidMount(){
    this._loadSections();
  }

  _loadSections(){
    let id = this.props.match.params.section_id;
    if (!id) this.props.history.push("/cms/sections");
    Backend.getSection(id).then((data) => {
      if (data) {
        this.setState({
          section: data,
          loading: false,
          showComponents: true,
        })
      }
    }).catch(error => {
      this.setState({
        loading: false,
        showComponents: true,
      })
      alert(error.message)
    });
  }

  _processComponentsToBeUpdated(components, callback) {
    Backend.getComponentTypes()
    .then((data) => {
      for (let component = 0; component < components.length; ++component) {
        components[component].component_id = components[component].component_details.id;
        components[component].content_type = components[component].type;
        // Replacing content_type text value with id (because otherwise backend won't be able to process components);
        for (let content_type of data) {
          if (components[component].content_type === content_type.label) {
            components[component].content_type = content_type.value;
          }
        }
        delete components[component].type;
        delete components[component].component_details;
      }
      callback();
    })
    .catch(error => {
      alert(error.message);
      document.location.reload();
    });
  }

  _onUpdateOrderInSection = (data) => {
    const copy_section = Object.assign({}, this.state.section);
    const components_copy = [];
    data.forEach((component) => {
      const copy_component = Object.assign({}, component); 
      components_copy.push({
        component_details: copy_component,
        order: copy_component.order,
        type: copy_component.type
      });
      delete copy_component.type;
      delete copy_component.order;
    });
    copy_section.components = components_copy;
    
    this._processComponentsToBeUpdated(copy_section.components, () => {
      Backend.editSection(copy_section).catch(error => alert(error.message));
    });
  }

  render() {
    let {
      loading,
      showAddModal,
      section,
    } = this.state

    if (loading) {
      return null
    }

    return (
      <div>
        <div className="row">
          <Card
            title={"Section: " + section.name}
            className="col-xl-12"
            renderToolbar={() => {
              return (
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    &nbsp;
                    <div className="dropdown dropdown-inline">
                      <button
                        type="button"
                        className="btn btn-brand btn-icon-sm"
                        onClick={() => {this.setState({showAddModal: true})}}
                      >
                        <i className="flaticon2-plus"></i> Add Component
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          >
            {this.state.showComponents &&
              <ComponentsTable
                section={section}
                onUpdateOrderInSection={this._onUpdateOrderInSection}
                onRemoveComponentClick={component => {
                  this._processComponentsToBeUpdated(section.components, () => {
                    for (let index = 0; index < section.components.length; ++index) {
                      if (section.components[index].component_id === component.id) {
                        section.components.splice(index, 1);
                        break;
                      }
                    }
                    Backend.editSection(section)
                      .then(() => {
                        this.setState({ showComponents: false });
                        setTimeout(() => {
                          this._loadSections();
                        });
                      })
                      .catch((error) => {
                        alert(error.message);
                        this._loadSections();
                      });
                  });
                }}
              />}
          </Card>
          <AddComponentToSectionModal
            processComponents={this._processComponentsToBeUpdated}
            show={showAddModal}
            section={section}
            onSubmit={() => {
              this.setState({ showAddModal: false })
              this.setState({showComponents: false});
              setTimeout(() => {
                this._loadSections();
              });
            }}
            onHide={() => {
              this.setState({ showAddModal: false })
            }}
          />
        </div>
      </div>
    )
  }
}
