import React from "react"

import General from '../../../utils/General'

export default class CardItem extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      color: General.getRandomColorState(),
      heading: props.heading,
      subHeading: props.subHeading,
      avatar: props.avatar,
      type: props.type
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    return (
      <>
        <div className="kt-widget4__item">
          <div className="kt-widget4__pic kt-widget4__pic--pic">
          </div>
          <div className="kt-widget4__info">
            <a
              className="kt-widget4__username"
              onClick={e => {
                e.preventDefault()
                this.props.onClick()
              }}
            >
              { this.state.heading }
            </a>
            <p className="kt-widget4__text">
              { this.state.subHeading }
            </p>
          </div>
          {/*
          <a href="#"
            className={`btn btn-sm btn-label-${color} btn-bold`}
            onClick={e => {
              e.preventDefault()
              this.props.onClick()
            }}
          >
            View
          </a>
          */}
        </div>
      </>
    )
  }
}

CardItem.defaultProps = {
  onClick: () => {}
}
