import React from "react"

import ScreenDetail from './components/ScreenDetail'

import Backend from '../utils/Backend'

export default class EditScreen extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      screen: null,
      loading: true,
    }
  }

  componentDidMount(){
    this._loadScreen()
  }

  _loadScreen(){
    let id = this.props.match.params.screen_id
    if(!id){
      this.setState({ loading: false })
      return
    }

    Backend.getScreen(id)
    .then(screen => {
      this.setState({
        screen,
        loading: false
      })
    })
  }

  _goToScreens(){
    this.props.history.push(`/cms/screens`)
  }

  render() {
    let {
      screen,
      loading
    } = this.state

    if (loading) {
      return null
    }

    return (
      <ScreenDetail
        screen={screen}
        goToScreens={() => this._goToScreens()}
      />
    )
  }
}
