import React from "react"

import StatCard from './components/cards/StatCard'
import Card from './components/cards/Card'
import AdvertsTable from './components/tables/AdvertsTable'

import Backend from '../utils/Backend'

export default class Adverts extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      searchTerm: '',
      statusFilters: [],
    }
  }

  componentDidMount(){
    this._loadStats()
  }

  _loadStats(){
    Backend.getAdvertStats()
    .then(stats => {
      this.setState({
        stats,
        loading: false
      })
    })

  }

  render() {
    let {
      stats,
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <div>
        <div className="row">
  				<StatCard
            title="Impressions"
            value={ stats.total.impressions }
            size="col-md-3 col-lg-3 col-xl-3"
          />
          <StatCard
            title="Clicks"
            value={ stats.total.clicks }
            size="col-md-3 col-lg-3 col-xl-3"
          />
  			</div>
        <div className="row">
          <Card
            title="Adverts"
            className="col-xl-12"
            renderToolbar={() => {
              return (
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    &nbsp;
                    <div className="dropdown dropdown-inline">
                      <button
                        type="button"
                        className="btn btn-brand btn-icon-sm"
                        onClick={() => this.props.history.push("/create-advert")}
                      >
                        <i className="flaticon2-plus"></i> New Advert
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          >
            <AdvertsTable
              onDeactivatedAdvert={() => {
                stats.active_no -= 1
                this.setState({ stats })
              }}
              onActivatedAdvert={() => {
                stats.active_no += 1
                this.setState({ stats })
              }}
              onAddAdvertClicked={() => {

              }}
              onEditAdvertClick={advert => {
                this.props.history.push(`/adverts/${advert.id}`)
              }}
            />
          </Card>
        </div>
      </div>
    )
  }
}
