import React from "react"

import BaseTable from "./BaseTable"

import Backend from '../../../utils/Backend'

export default class SkupeNetDealersTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }
  }

  _add(name, key){
    Backend.addSkupeNetDealer(name, key)
  }

  _getColumns() {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Id',
        accessor: 'key'
      },
    ]
  }

  render() {
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          endpoint={window.Api.SkupeNetDealers}
          columns={columns}
        />
      </div>
    )
  }
}
