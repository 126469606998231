import React from "react"

import CollectionDetail from './components/CollectionDetail'

import Backend from '../utils/Backend'

export default class EditCollection extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      collection: null,
      loading: true,
    }
  }

  componentDidMount(){
    this._loadCollection()
  }

  _loadCollection(){
    let id = this.props.match.params.collection_id
    if(!id){
      this.setState({ loading: false })
      return
    }

    Backend.getCollection(id)
    .then(collection => {
      this.setState({
        collection,
        loading: false
      })
    })
  }

  _goToCollections(){
    this.props.history.push(`/collection/collections`)
  }

  render() {
    let {
      collection,
      loading
    } = this.state

    if (loading) {
      return null
    }

    return (
      <CollectionDetail
        collection={collection}
        goToCollections={() => this._goToCollections()}
      />
    )
  }
}
