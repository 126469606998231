import React from "react";

import moment from "moment";

import BaseTable from "./BaseTable";

import VehicleActionsCell from "./cells/VehicleActionsCell";

import Backend from "../../../utils/Backend";

export default class VehiclesTable extends React.Component {
  constructor(props) {
    super(props);

    let endpoint = window.Api.Vehicles;
    if (props.dealerMode) {
      endpoint += "?private=false";
    } else {
      endpoint += "?private=true";
    }

    this.state = {
      endpoint,
      dealerMode: props.dealerMode,
    };
  }

  _deactivate(vehicle) {
    Backend.deactivateVehicle(vehicle);
    this.props.onDeactivatedVehicle(vehicle);
  }

  _activate(vehicle) {
    Backend.activateVehicle(vehicle);
    this.props.onActivatedVehicle(vehicle);
  }

  _getColumns() {
    let { dealerMode } = this.state;

    let columns = [
      {
        Header: "Vehicle Id",
        accessor: "id",
      },
      {
        Header: "Brand",
        accessor: "model.manufacturer.name",
      },
      {
        Header: "Model",
        accessor: "model.name",
      },
      {
        Header: "Reg",
        accessor: "registration_number",
      },
      {
        Header: "Source",
        accessor: "source",
      },
    ];

    if (dealerMode) {
      columns.push({
        Header: "Dealer",
        accessor: "dealer.name",
      });
      columns.push({
        Header: "Dealer Active",
        id: "dealer.active",
        accessor: (vehicle) => (vehicle.dealer.active ? "yes" : "no"),
      });
    } else {
      columns.push({
        Header: "User",
        id: "customer.user.first_name",
        accessor: (vehicle) =>
          `${vehicle.customer.user.first_name} ${vehicle.customer.user.last_name}`,
      });
    }

    columns = [
      ...columns,
      {
        Header: "Price",
        id: "price",
        accessor: (vehicle) =>
          vehicle.price ? `€${vehicle.price / 100}` : "-",
      },
      {
        Header: "Mileage",
        accessor: "mileage",
      },
      {
        Header: "Created At",
        id: "created_at",
        accessor: (vehicle) => moment(vehicle.created_at).format("DD/MM/YYYY"),
      },
      {
        id: "active",
        Header: "Actions",
        Cell: (rowInfo) => {
          return (
            <VehicleActionsCell
              vehicle={rowInfo.original}
              onDeactivateClick={(vehicle) => this._deactivate(vehicle)}
              onActivateClick={(vehicle) => this._activate(vehicle)}
              onRefundClick={(vehicle) => Backend.refundPayment(vehicle)}
            />
          );
        },
      },
    ];

    return columns;
  }

  render() {
    let { endpoint } = this.state;

    const columns = this._getColumns();

    return (
      <div>
        <BaseTable endpoint={endpoint} columns={columns} />
      </div>
    );
  }
}

VehiclesTable.defaultProps = {
  dealerMode: true,
};
