import React from "react"

import Card from '../components/cards/Card'
import UnmappedItemsTable from "./UnmappedItemsTable"

export default class UnmappedItems extends React.Component {
  constructor(props){
    super(props)

    this.state = {
    }
  }
  render() {
    return (
      <div>
        
        <div className="row">
          <Card
            title="Unmapped Items"
            className="col-xl-12"
          >
            <UnmappedItemsTable />
          </Card>
        </div>
      </div>
    )
  }
}
